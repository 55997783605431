const orderTableCol = [
    { field: 'description', header: 'ORDER_CREATION.STYLE_NUMBER'},
    { field: 'operation_number', header: 'ORDER_CREATION.ORDER_NUMBER' },
    { field: 'std_time', header: 'ORDER_CREATION.STATUS'},
    { field: 'std_time', header: 'ORDER_CREATION.ORDER_DATE'},
    { field: 'std_time', header: 'ORDER_CREATION.FILE_HANDOVER_DATE'},
    { field: 'std_time', header: 'ORDER_CREATION.SHIPMENT_DATE'},
    { field: 'std_time', header: 'ORDER_CREATION.DELIVERY_DATE'},
    { field: 'std_time', header: 'ORDER_CREATION.EX_FACTORY_DATE'}
];
export {orderTableCol};

export class ColorTable {
}
