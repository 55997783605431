import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Http, Headers, Response } from '@angular/http';

@Injectable()
export class SharedService {
    permissions: Array<any> = [];
    authenticationToken: string;
    authorizedHeader = new HttpHeaders();
    token: any;

    feedbackSource = new Subject();
    feedback$ = this.feedbackSource.asObservable();


    dashboardSource = new Subject();
    dashboard$ = this.dashboardSource.asObservable();

    authDataSource = new Subject();
    authData$ = this.authDataSource.asObservable();

    constructor(
        public http: HttpClient,
        public router: Router
    ) {
        this.getUserAuthentication();
    }
    /**
     * For getting logged in user authorization token from localstorage
     */
    getUserAuthentication() {
        const loginInUserDetail = JSON.parse(localStorage.getItem('loggedInUser'));
        this.authenticationToken = loginInUserDetail ? loginInUserDetail.Authorization : '';
        this.authorizedHeader = new HttpHeaders().set('Authorization', `${this.token}`);
    }

    updateFeedbackModal(data) {
        this.feedbackSource.next(data);
    }

    /** shared factory and freequency type  */
    updateDashboard(data) {
        this.dashboardSource.next(data);
    }

    /** shared Role Data type  */
    updateAuthData(data) {
        this.authDataSource.next(data);
    }
}
