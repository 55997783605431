import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(
    private http: HttpClient,
    private httpClientService: HttpclientService,
    private httpService: HttpClient
  ) {}

  getFactoryId() {
    const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
    return this.httpClientService.get(url);
  }

  getOrdersList() {
    const url = `${window.location.origin}/apigateway/style/api/v1/order`;
    return this.httpClientService.get(url);
  }

  /**
   * get sizes of selected style
   * @param id selected style id
   */
  getstyleSize(styleId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/sizes/style?styleId=${styleId}`;
    return this.httpClientService.get(url);
  }

  /**
   * create order
   * @param id
   */
  createOrder(data, styleId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/order?styleId=${styleId}`;
    return this.httpClientService.post(url, data);
  }

  /**
   * update order
   * @param id
   */
  updateOrder(data, orderId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/order?orderId=${orderId}`;
    return this.httpClientService.put(url, data);
  }

  /**
   * delete order
   */
  deleteOrder(id) {
    const url = `${window.location.origin}/apigateway/style/api/v1/order?orderId=` + id;
    return this.httpClientService.remove(url, id);
  }

  /**
   * 
   */
  searchStyles(styleName, factoryId) {
    var listOfStyle = this.httpService
      .get(`apigateway/style/api/v1/auto/complete/style?styleName=${styleName}&factoryId=${factoryId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length !== 0 ? (data as any[]) : [{ 'Style Name': 'No Style Found' } as any];
        })
      );
    return listOfStyle;
  }

  /**
   * create size for styles 
   */
  createSize(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/size`;
    return this.httpClientService.post(url, data);
  }
}
