import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(_httpClient: HttpClient) {
   return new TranslateHttpLoader(_httpClient, 
    './assets/i18n/', '.json' 
  );
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule, 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory:HttpLoaderFactory,
        deps: [HttpClient]  
      }
    })
  ],
  providers:[   
        {
        provide: LOCALE_ID,
        useValue: navigator.language,
    },
  ]
})
export class MyTranslateModule { }
