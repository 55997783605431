import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProSMVService {
    constructor(
        private http: HttpClient,
        private httpClientService: HttpclientService,
        private httpService: HttpClient
    ) { }

    /**
     * get factory id of logged in user
     */
    getFactoryId() {
        const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
        return this.httpClientService.get(url);
    }

    /**
     * search for avail styles
     */
    searchOperation(opName, factoryId) {
        const listOfStyle = this.httpService
            .get(`apigateway/operation/api/v1/autocomplete/operation?operationNumber=${opName}&factoryId=${factoryId}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Style Name': 'No Style Found' } as any];
                })
            );
        return listOfStyle;
    }

    /**
     * search size grid
     */
    searchSizGrid(gridName, factoryId) {
        const listOfStyle = this.httpService
            .get(`apigateway/operation/api/v1/autocomplete/gridSize?factoryId=
                ${factoryId}&gridValue=${gridName}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Style Name': 'No Style Found' } as any];
                })
            );
        return listOfStyle;
    }

    /**
     * search size grid
     */
    searchSize(sizeName, gridId) {
        const listOfStyle = this.httpService
            .get(`apigateway/operation/api/v1/autocomplete/size?gridSizeId=${gridId}&size=${sizeName}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Style Name': 'No Style Found' } as any];
                })
            );
        return listOfStyle;
    }

   

    /**
     * Fetch PRO SMV COde Data
     */
    getProSmvCode() {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/smvCode`;
        return this.httpClientService.get(url);
    }

    /**
     * Fetch PRO SMV COde Data
     */
    getOpData(opId, sizeGridId, sizeId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operations/details?operationId=
        ${opId}&gridSizeId=${sizeGridId}&sizeId=${sizeId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * fet operation analysis history
     * @param opId operation Id
     */
    getAnalysisHistory(opId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/analysisHistory?operationId=${opId}`;
        return this.httpClientService.get(url);
    }

    /**
     * Fetch chart data
     * @param  factoryId user factory id
     */
    getChartData(factoryId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operations/chart/data?factoryId=${factoryId}`;
        return this.httpClientService.get(url);
    }

    /**
     * API call to save analysis
     */
    createAnalysis(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/create/analysis`;
        return this.httpClientService.post(url, data);
    }

    /**
     * API call to update Analysis
     */
    updateAnalysis(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/analysis/calculation`;
        return this.httpClientService.post(url, data);
    }

    /**
     * send for Approval
     */
    SendApproval(headerId, opId, sizeGridId, sizeId, analysisId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/analysis/sendApproval?analysisHeaderId=
        ${headerId}&operationId=${opId}&gridId=${sizeGridId}&sizeId=${sizeId}&operationAnalysisId=
        ${analysisId}&stageId=${stageId}`;
        return this.httpClientService.post(url, {});
    }

    /**
     * disApprove Operation
     */
    disapproveOperation(headerId, opId, sizeGridId, sizeId, analysisId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/analysis/rejected?analysisHeaderId=
        ${headerId}&operationId=${opId}&gridId=${sizeGridId}&sizeId=${sizeId}&operationAnalysisId=
        ${analysisId}&stageId=${stageId}`;
        return this.httpClientService.post(url, {});
    }

    /**
     * Approve Operation
     */
    approveOperation(headerId, opId, sizeGridId, sizeId, analysisId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/analysis/approved?analysisHeaderId=
        ${headerId}&operationId=${opId}&gridId=${sizeGridId}&sizeId=${sizeId}&operationAnalysisId=
        ${analysisId}&stageId=${stageId}`;
        return this.httpClientService.post(url, {});
    }

    /**
     * API call to SEW Calculation
     */
    sewCalculation(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/analysis/sew/calculation`;
        return this.httpClientService.post(url, data);
    }

    /**
     * PRE / PRM calculation
     */
    preprmCalculation(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/analysis/other/calculation`;
        return this.httpClientService.post(url, data);
    }

    /**
     * get stage List
     */
    getStages() {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/stages`;
        return this.httpClientService.get(url);
    }
    getSizes(opId, gridId, stageId){
const url = `${window.location.origin}/apigateway/operation/api/v1/operation/size?operationId=${opId}&gridSizeId=${gridId}&stageId=${stageId}`;
        console.log(url)
        return this.httpClientService.get(url);
    }

    copySizes({operationId,sizeGridId,oldSizeId,newSizeId,stageId}){
const url = `${window.location.origin}/apigateway/operation/api/v1/operation/copy?operationId=${operationId}&gridSizeId=${sizeGridId}&oldSizeId=${oldSizeId}&newSizeId=${newSizeId}&stageId=${stageId}`;
        return this.httpClientService.post(url,{});
    }
    /**
     * update desc
     */
    updateDesc(desc, id) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/update/analysis?operationSubAnalysisId=
        ${id}&description=${desc}`;
        return this.httpClientService.put(url, {});
    }

    /**
     * print Analysis Data
     */
    printAnalysisData(factoryId, opId, gridId,sizeId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/print/analysis/report?factoryId=
        ${factoryId}&operationId=${opId}&gridSizeId=${gridId}&sizeId=${sizeId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    downloadFile(fileName) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/analysis/download/${fileName}`;
        return this.httpClientService.getFile(url);
      }
    
    getSizeGridSizes(factoryId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/sizeGridSize/${factoryId}`;
    return this.httpClientService.get(url);
    }
}
