import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { debounceTime, map } from 'rxjs/operators';

@Injectable()
export class ThreadConsumptionService {
    constructor(
        private http: HttpClient,
        private httpClientService: HttpclientService,
        private httpService: HttpClient
    ) { }

    /**
     * get factory id of logged in user
     */
    getFactoryId() {
        const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
        return this.httpClientService.get(url);
    }

    /**
     * API CAll to fetch stages from Server
     */
    getStages() {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/stages`;
        return this.httpClientService.get(url);
      }

    /**
     * search for avail styles
     */
    searchStyles(styleName, factoryId) {
        const listOfStyle = this.httpService
            .get(`apigateway/style/api/v1/auto/complete/style?styleName=${styleName}&factoryId=${factoryId}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Style Name': 'No Style Found' } as any];
                })
            );
        return listOfStyle;
    }

    /**
     * search for avail styles
     */
    searchMachine(machineName, factoryId) {
        const listOfStyle = this.httpService
            .get(`/apigateway/style/api/v1/auto/complete/machine?machineName=${machineName}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Style Name': 'No Style Found' } as any];
                })
            );
        return listOfStyle;
    }

    /**
     * fetch lists of thread consumptions
     */
    getThreadsList(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/threadConsumption/style?styleId=
        ${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * 
     */
    getMachineThreadsList(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/threadQuality/machines?styleId=
        ${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * 
     */
    saveMachineThread(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/threadQuality/machine`;
        return this.httpClientService.post(url, data);
    }

    /**
     * 
     */
    updateMachineThread(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/threadQuality/machine`;
        return this.httpClientService.put(url, data);
    }
    
     getThreadPrint(styleId, stageId) {
const url =`${window.location.origin}/apigateway/operation/api/v1/threadConsumption/style/print?styleId=${styleId}&stageId=${stageId}`;
    return this.httpClientService.get(url);
  }

  downloadFile(fileName) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/operation/download/${fileName}`;
    return this.httpClientService.getFile(url);
  }
}
