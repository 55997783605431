import { Injectable } from '@angular/core';
import { HttpclientService } from './httpclient.service';

@Injectable()
export class CommonService {

  constructor(
    public httpClientService: HttpclientService
  ) { }

  getRoleAndPermission() {
    const url = `${window.location.origin}/apigateway/admin/api/v1/user/role`;
    return this.httpClientService.get(url);
  }
}
