import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyComponent } from './company/company.component';
import { FactoryComponent } from './factory/factory.component';
import { LoginComponent } from './login/login.component';
import { RolesComponent } from './roles/roles.component';
import { UsersComponent } from './users/users.component';
import { StylesComponent } from './styles/styles.component';
import { ProComponent } from './pro/pro.component';
import { LicenseComponent } from './license/license.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerAndSupplierComponent } from './customer-and-supplier/customer-and-supplier.component';
import { OperationsComponent } from './operations/operations.component';
import { MachineComponent } from './machine/machine.component';
import { SubsectionComponent } from './subsection/subsection.component';
import { SequenceComponent } from './sequence/sequence.component';
import { ThreadConsumptionComponent } from './thread-consumption/thread-consumption.component';
import { OrderCreationComponent } from './order-creation/order-creation.component';
import { CopyOperationsComponent } from './copy-operations/copy-operations.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { FeedBackComponent } from './feed-back/feed-back.component';

const routes: Routes = [
  {
    path: 'company',
    component: CompanyComponent,
    data: {
      page: 'Company',
    }
  },
  {
    path: 'factory',
    component: FactoryComponent,
    data: {
      page: 'Factory',
    }
  },
  {
    path: 'roles',
    component: RolesComponent,
    data: {
      page: 'Roles',
    }
  },
  {
    path: 'users',
    component: UsersComponent,
    data: {
      page: 'Users',
    }
  },
  {
    path: 'styles',
    component: StylesComponent,
    data: {
      page: 'Styles',
    }
  },
  {
    path: 'pro',
    component: ProComponent,
    data: {
      page: 'Pro',
    }
  },
  {
    path: 'license',
    component: LicenseComponent,
    data: {
      page: 'License',
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      page: 'Dashboard',
    }
  },
  {
    path: 'dashboard-mobile',
    component: DashboardComponent,
    data: {
      page: 'Dashboard Mobile',
    }
  },
  {
    path: 'customer-and-supplier',
    component: CustomerAndSupplierComponent,
    data: {
      page: 'Customer and Supplier',
    }
  },
  {
    path: 'operation',
    component: OperationsComponent,
    data: {
      page: 'Operation',
    }
  },
  {
    path: 'machine',
    component: MachineComponent,
    data: {
      page: 'Machine',
    }
  },
  {
    path: 'subsection',
    component: SubsectionComponent,
    data: {
      page: 'Sub Section',
    }
  },
  {
    path: 'orderSequence',
    component: SequenceComponent,
    data: {
      page: 'Order Sequence',
    }
  },
  {
    path: 'threadConsumption',
    component: ThreadConsumptionComponent,
    data: {
      page: 'Thread Consumption',
    }
  },
  {
    path: 'orderCreation',
    component: OrderCreationComponent,
    data: {
      page: 'Order Creation',
    }
  },
  {
    path: 'copyOperations',
    component: CopyOperationsComponent,
    data: {
      page: 'Copy Operations',
    }
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    data: {
      page: 'Forget Password',
    }
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    data: {
      page: 'Reset Password',
    }
  },
  {
    path: 'recoverPassword',
    component: RecoverPasswordComponent,
    data: {
      page: 'Recover Password',
    }
  },
  {
    path: '',
    component: LoginComponent,
    data: {
      page: 'Login',
    }
  },
  {
    path: 'feedBack',
    component: FeedBackComponent,
    data: {
      page: 'FeedBack',
    }
  }
  // { path: '**', redirectTo: '' }
];
export const routing = RouterModule.forRoot(routes);
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
