import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpclientService } from '../shared/httpclient.service';

@Injectable()
export class ChatService {
    headers;
    accessToken;
    serverUrl = 'https://www.prosmv.in';

    constructor(
        public http: Http,
        public httpClientService: HttpclientService) {
        this.accessToken = localStorage.getItem('chatToken');
        this.assignHeaders(this.accessToken);
    }
    assignHeaders(accessToken): void {
        const headers: Headers = new Headers();
        headers.append('Authorization', `bearer ${accessToken}`);
        this.headers = headers;
    }

    getAllUsers() {
        const url = `${window.location.origin}/apigateway/admin/api/v1/matrix/users`;
        return this.httpClientService.get(url);
    }

    getJoinedRooms(accessToken): Promise<any> {
        const url = `${this.serverUrl}/_matrix/client/r0/joined_rooms`;
        const query = {
            access_token: accessToken
        };
        return this.http.get(url, { search: query })
            .toPromise()
            .then((response) => {
                return response.json();
            }, (error) => {
            });
    }

    getJoinedMembers(roomId, accessToken): Promise<any> {
        let url = `${this.serverUrl}/_matrix/client/r0/rooms/:roomId/members`;
        url = url.replace(':roomId', roomId);
        const query = {
            access_token: accessToken
        };
        return this.http.get(url, { search: query })
            .toPromise()
            .then((response) => {
                return response.json();
            }, (error) => {
            });
    }

    getSyncRooms(accessToken, nextBatchToken?): Promise<any> {
        const url = `${this.serverUrl}/_matrix/client/r0/sync`;
        const query = {
            timeout: 30000,
            // filter: 0,
            access_token: accessToken
        };
        if (nextBatchToken) {
            query['since'] = nextBatchToken;
        }
        return this.http.get(url, { search: query })
            .toPromise()
            .then((response) => {
                return response.json();
            }, (error) => {
                return Promise.reject(error);
            });
    }

    // getDisplayName(userId, accessToken) {
    //     let url = this.getDisplayNameUrl;
    //     url = url.replace(':userId', userId);

    //     // const query = {
    //     //     access_token: accessToken
    //     // };
    //     return this.http.get(url, { headers: this.headers })
    //         .toPromise()
    //         .then((response) => {
    //             return response.json();
    //         }, (error) => {
    //         });
    // }

    getRoomMessages(roomId, fromToken, accessToken): Promise<any> {
        let url = `${this.serverUrl}/_matrix/client/r0/rooms/:roomId/messages`;
        url = url.replace(':roomId', roomId);
        const query = {
            from: fromToken,
            dir: 'b',
            limit: 240,
            access_token: accessToken
        };
        return this.http.get(url, { search: query })
            .toPromise()
            .then((response) => {
                return response.json();
            }, (error) => {
            });
    }

    sendRoomMessages(data, roomId, accessToken): Promise<any> {
        const sendData = {
            msgtype: 'm.text',
            body: data
        };
        const query = {
            access_token: accessToken
        };
        const currentTime: any = Date.now();
        let url = `${this.serverUrl}/_matrix/client/r0/rooms/:roomId/send/m.room.message/:unixTime`;
        url = url.replace(':roomId', roomId).replace(':unixTime', currentTime);
        return this.http.put(url, sendData, { search: query })
            .toPromise()
            .then((response) => {
                return response.json();
            }, (error) => {
            });
    }

    getGroupName(roomId, accessToken): Promise<any> {
        const query = {
            access_token: accessToken
        };
        let url = `${this.serverUrl}/_matrix/client/r0/rooms/:roomId/state/:eventType`;
        url = url.replace(':roomId', roomId).replace(':eventType', 'm.room.name');
        return this.http.get(url, { search: query })
            .toPromise()
            .then((response) => {
                return response.json();
            }, (error) => {
            });
    }

    removeUserFromGroup(userId, roomId, accessToken): Promise<any> {
        const query = {
            access_token: accessToken
        };
        const data = {
            reason: 'nothing',
            user_id: userId
        };
        let url = `${this.serverUrl}/_matrix/client/r0/rooms/:roomId/kick`;
        url = url.replace(':roomId', roomId);
        return this.http.post(url, data, { search: query })
            .toPromise()
            .then((response) => {
                return response.json();
            }, (error) => {
            });
    }

    addUserToGroup(userId, roomId, accessToken): Promise<any> {
        const query = {
            access_token: accessToken
        };
        const data = {
            user_id: userId
        };
        let url = `${this.serverUrl}/_matrix/client/api/v1/rooms/:roomId/invite`;
        url = url.replace(':roomId', roomId);
        return this.http.post(url, data, { search: query })
            .toPromise()
            .then((response) => {
                return response.json();
            }, (error) => {
            });
    }

    updateGroupName(newName, roomId, accessToken): Promise<any> {
        const query = {
            access_token: accessToken
        };
        const roomNameObj = {
            name: newName
        };
        let url = `${this.serverUrl}/_matrix/client/r0/rooms/:roomId/state/:eventType`;
        url = url.replace(':roomId', roomId).replace(':eventType', 'm.room.name');
        return this.http.put(url, roomNameObj, { search: query })
            .toPromise()
            .then((response) => {
                return response.json();
            }, (error) => {
            });
    }

}
