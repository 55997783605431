import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, bounceIn, rollIn, flipInY } from 'ng-animate';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpclientService } from '../shared/httpclient.service';
import { UserService } from './user.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Http } from '@angular/http';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  animations: [
    // trigger('flip', [transition('* => *', useAnimation(flip))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))])
  ],
  styleUrls: ['./users.component.sass']
})
export class UsersComponent implements OnInit {
  displayMode = 1;
  flip: any;
  bounce: any;
  bounceIn: any;
  rollIn: any;
  userListDTO: any;
  createUser: any = {};
  userUpdate: any = {};
  searchCompany: FormControl = new FormControl();
  companySearchList: Array<any> = [];
  text: string;
  results: Array<any> = [];
  resultsFactory: Array<any> = [];
  resultsRole: Array<any> = [];
  companyId: any;
  coList: any;
  roleList: any;
  factoryList: any;
  optionSelected: string;
  searchText: any;
  filteredCountriesSingle: any[];
  countries: any[];
  resultFactory;
  isSuperAdmin: any;
  userId: string;
  spinner: boolean;
  roleId: any;
  userAuthData: any = {};
  usersData: Array<any> = [];

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private userService: UserService,
    private toastr: ToastrService,
    private service: UserService,
    private router: Router,
    public sharedService: SharedService
  ) {
    this.optionSelected = '';
    this.createUser.name = null;
    this.createUser.username = null;
    this.createUser.password = null;
    this.createUser.confirmPassword = null;
    this.createUser.mobileNumber = null;
    this.createUser.email = null;
    this.createUser.roleId = this.resultsFactory;
    // this.createUser.factoryId = this.resultsRole;
    this.createUser.factoryNames = [];
    this.userUpdate.factoryNames = [];
    this.createUser.active = true;
    this.createUser.companyId = this.companyId;
  }

  ngOnInit() {
    this.getAuthData();
    this.isSuperAdmin = localStorage.getItem('isSuperAdmin');
    this.searchCompany.valueChanges.subscribe(companyName => {
      if (companyName !== '') {
        this.service.search(companyName).subscribe((data: any) => {
          const companyList = [];
          for (const comp of data.data) {
            comp.name = comp.name;
            companyList.push(comp.id);
          }
          this.companySearchList = companyList;
        });
      }
    });
    this.getUserList();
  }

  /**
   * fetch role and permissions assigned to it
   */
  getAuthData() {
    const auth = this.sharedService.authData$.subscribe((data: any) => {
      const authData = data.find((el) => {
        return el.name === 'File & Setup';
      });
      const userAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'User';
      });
      this.userAuthData = userAuthData;
    });
  }

  /**
   * switch user
   */
  switchUser(userName) {
    this.service.switchUser(userName).subscribe((response: any) => {
      const switchUserData = {
        userName: localStorage.getItem('username'),
        isSwitched: true
      };
      localStorage.setItem('lastUser', JSON.stringify(switchUserData));
      if (response.data.authenticationToken != null) {
        localStorage.setItem('authenticationToken', response.data.authenticationToken);
        localStorage.setItem('userType', response.data.user.userType);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('isSuperAdmin', response.data.user.isSuperAdmin ?
          response.data.user.isSuperAdmin : false);
        this.spinner = false;
        this.router.navigate(['/dashboard']);
        this.toastr.success(response.message, 'Success');
      }
    });
  }
  /**
   * search table data
   */
  searchOpDesc(event) {
    const keyToMatch = this.optionSelected ? this.optionSelected : 'username';
    this.userListDTO = this.usersData.filter(item => {
      if (item[keyToMatch].toString().toLowerCase().indexOf(event.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    });
    // this.service.searchByDescOP(event, this.factoryId).subscribe((response: any) => {
    //   this.sales = response.data;
    // });
  }

  search(event) {
    this.service.search(event.query).subscribe((response: any) => {
      const companyList = [];
      for (const comp of response.data) {
        comp.name = comp.name;
        companyList.push(comp.name);
        // this.companyId = comp.id;
      }
      this.results = companyList;
      this.coList = response.data;
    });
  }

  captureId(event: any) {
    for (var i = 0; i < this.coList.length; i++) {
      if (this.coList[i].name === event) {
        this.companyId = this.coList[i].id;
      }
    }
  }

  filterCountryMultiple(event) {
    // this.companyId = this.userUpdate.companyId;
    this.service.searchFactory(event.query, this.companyId).subscribe((response: any) => {
      const factoryList = [];
      for (const factory of response.data) {
        factory.name = factory.name;
        factoryList.push(factory.name);
        // this.companyId = comp.id;
      }
      this.resultsRole = factoryList;
      this.factoryList = response.data;
    });
  }
  searching(event) {
    this.userUpdate.companyId = this.companyId;
    this.service.searching(event.query, this.companyId).subscribe((response: any) => {
      const roleList = [];
      for (const role of response.data) {
        role.roleName = role.roleName;
        roleList.push(role.roleName);
        // this.companyId = comp.id;
      }
      this.resultsFactory = roleList;
      this.roleList = response.data;
    });
  }

  captureRoleId(event: any) {
    for (var i = 0; i < this.roleList.length; i++) {
      if (this.roleList[i].roleName === event) {
        this.roleId = this.roleList[i].id;
      }
    }
  }

  searchFactory(event) {
    // this.companyId = this.userUpdate.companyId;
    this.service.searchFactory(event.query, this.companyId).subscribe((response: any) => {
      const factoryList = [];
      for (const factory of response.data) {
        factory.name = factory.name;
        factoryList.push(factory.name);
        // this.companyId = comp.id;
      }
      this.resultFactory = factoryList;
      this.factoryList = response.data;
    });
  }

  captureFactoryId(event: any) {
    for (const item of this.factoryList) {
      if (item.name === event) {
        this.resultsRole = item.id;
      }
    }
    this.createUser.factoryNames.push(event);
    this.userUpdate.factoryNames.push(event);
  }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  openNewUserModal() {
    this.ngxSmartModalService.getModal('myModal').open();
  }

  openUserUpdateModal(data) {
    this.ngxSmartModalService.getModal('myModal1').open(data);
    this.userUpdate.userId = data.id;
    this.userUpdate.companyId = data.companyDTO.id;
    this.companyId = this.userUpdate.companyId;
    this.userUpdate.roleName = data.roleName;
    this.userUpdate.username = data.username;
    this.userUpdate.name = data.name;
    this.userUpdate.password = data.password;
    this.userUpdate.mobileNumber = data.mobileNumber;
    this.userUpdate.email = data.email;
    this.userUpdate.companyName = data.companyDTO.name;
    this.roleId = data.roleId;
    // this.userUpdate.userId = data.id;
    this.userUpdate.factoryNames = data.factoryDTOs.map(item => {
      return item.name;
    });
    this.userUpdate.factoryId = data.factoryDTOs[0].id;
    this.userUpdate.active = data.active;
    // this.userUpdate.companyId = this.companyId;
  }

  checkValue(userId: number, status: boolean) {
    // if (!userId) {
    //   userId = this.userUpdate.userId;
    // }
    this.userService.statusUser(userId, status).subscribe((response: any) => {
      this.toastr.success('User Status Updated', 'Success');
    });
  }

  onUserUpdate(form: NgForm) {
    // this.userUpdate.companyId = 56;
    this.userUpdate.roleId = this.roleId
    this.userService.updateUser(this.userUpdate).subscribe(
      result => {
        form.reset();
        // window.location.reload();
        this.toastr.success(result.message, 'Success');
        this.getUserList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  getUserList() {
    this.userService.getUser().subscribe((response: any) => {
      if (response.data) {
        this.userListDTO = response.data;
        for (const users of this.userListDTO) {
          users.companyName = users.companyDTO.name;
          users.factoryName = users.factoryDTOs.name;
        }
        this.usersData = this.userListDTO;
      } else {
      }
    });
  }

  onUserCreate(form: NgForm) {
    this.createUser.companyId = this.companyId;
    this.createUser.roleId = this.roleId;
    this.userService.createUser(this.createUser).subscribe(
      result => {
        form.reset();
        // window.location.reload();
        this.toastr.success(result.message, 'Success');
        this.getUserList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  deleteUser(list) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        this.userService.deleteUser(list.id).subscribe((response: any) => {
          const index: number = this.userListDTO.indexOf(list);
          this.userListDTO.splice(index, 1);
          this.toastr.success(response.message, 'Success');
        });
      }
    });
  }
}
