import { Component, OnInit, HostListener, ViewChildren, ViewChild } from '@angular/core';
import { ChatService } from './chat.service';
import * as matrixcs from 'matrix-js-sdk';
import { isYesterday, isToday } from 'date-fns';
import { find, sortBy, findIndex, cloneDeep, filter, map } from 'lodash';
import { ChatWindow, User } from './chat.model';

@Component({
    selector: 'app-chat',
    templateUrl: 'chat.component.html',
    styleUrls: ['./chat.component.scss', './chat-responsive.scss'],
    providers: [ChatService]
})

export class ChatComponent implements OnInit {
    // available area to render the plugin
    viewPortTotalArea: number = window.innerWidth;
    loggedInStaffObj: any = {};
    displayStaffList: Array<any> = [];
    chatWindows: Array<ChatWindow> = [];
    nextBatchToken: any;
    currentRoomId: any;
    myUserId: any;
    matrixClient: any;
    accessToken: string;
    messageContent: string;
    displayGroupList: Array<any> = [];
    searchStaff = '';
    results: Array<any> = [];
    searchStaffInList: string;
    serverUrl = 'https://www.prosmv.in';
    @ViewChildren('chatMessages') chatMessageClusters: any;
    @ViewChild('capture') capture: any;
    // total width size of the friends list section
    friendsListWidth: number;
    // defines the size of each opened window to calculate how many windows can be opened on the viewport at the same time.
    windowSizeFactor: number;
    isChatOpened = false;



    constructor(
        public chatService: ChatService,
    ) {
        this.searchStaffInList='';
    }

    ngOnInit(): void {
        this.normalizeWindows();
        this.getUserList();

    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.viewPortTotalArea = event.target.innerWidth;
        this.normalizeWindows();

    }

    /**
     * Checks if there are more opened windows than the view port can display
     */
    normalizeWindows(): void {
        const maxSupportedOpenedWindows = Math.floor(this.viewPortTotalArea / this.windowSizeFactor);
        const difference = this.chatWindows.length - maxSupportedOpenedWindows;

        if (difference >= 0) {
            this.chatWindows.splice(this.chatWindows.length - 1 - difference);
        }

        if (this.viewPortTotalArea > 1367) {
            this.windowSizeFactor = 640;
            this.friendsListWidth = 618;
        } else {
            this.windowSizeFactor = 350;
            this.friendsListWidth = 300;
        }
        if (this.viewPortTotalArea < 600) 
        {
            this.windowSizeFactor = 55;
            this.friendsListWidth = 300;
        }
    }

    bootstrapChat(loggedInStaffObj): void {
        this.loggedInStaffObj = loggedInStaffObj || {};
        // this.chatService.assignHeaders(loggedInStaffObj.access_token);
        this.accessToken = loggedInStaffObj.access_token;
        this.myUserId = loggedInStaffObj.userId;
        const myUserId = loggedInStaffObj.userId;
        this.chatService.accessToken = loggedInStaffObj.access_token;
        this.matrixClient = matrixcs.createClient({
            baseUrl: this.serverUrl,
            userId: myUserId,
            is_direct: true,
            accessToken: loggedInStaffObj.access_token
        });
        this.initialSyncOfChat();
    }

    /**
     * Working
     */
    initialSyncOfChat(): void {
        this.chatService.getSyncRooms(this.accessToken)
            .then((response: any) => {
                this.nextBatchToken = response.next_batch;
                const roomIdsObj = response.rooms.join;
                const inviteIdsObj = response.rooms.invite;
                const presenceEvents = response.presence.events;
                this.updateOnlinePresenceOfStaffs(presenceEvents);
                this.getCreatedRoomsInfo(roomIdsObj);
                this.autoJoinInvitedRooms(inviteIdsObj);
                this.syncingOfData(this.nextBatchToken);
            }, (error) => {
            });
    }

    /**
     * To get all Users list
     */
    getUserList() {
        this.chatService.getAllUsers().subscribe((users: any) => {
            this.displayStaffList = users.data;
            const userData = {
                access_token: localStorage.getItem('chatToken'),
                userId: localStorage.getItem('loggedInUserId'),
                username: localStorage.getItem('username')
            };
            this.bootstrapChat(userData);
        });
    }

    getCreatedRoomsInfo(roomIdsObj: any): void {
        for (const roomId in roomIdsObj) {
            if (roomIdsObj.hasOwnProperty(roomId)) {
                this.getRoomInfo(roomId);
            }
        }
    }

    autoJoinInvitedRooms(inviteIdsObj: any): void {
        for (const roomId in inviteIdsObj) {
            if (inviteIdsObj.hasOwnProperty(roomId)) {
                this.matrixClient.joinRoom(roomId)
                    .then((response: any) => {
                        this.getRoomInfo(roomId);
                    }, (error) => {
                    });
            }
        }
    }

    updateOnlinePresenceOfStaffs(presenceEvents: any): void {
        presenceEvents.forEach((event) => {
            if (event.type === 'm.presence') {
                const user = event.sender;
                const findUser = find(this.displayStaffList, ['matrixUserId', event.sender]);
                if (findUser) {
                    if (event.content.presence === 'online') {
                        findUser.online = true;
                    } else {
                        findUser.online = false;
                    }
                }
            }
        });
    }

    getRoomInfo(roomId): void {
        this.chatService.getJoinedMembers(roomId, this.accessToken)
            .then((response: any) => {
                const eventChunks = response.chunk;
                if (eventChunks.length === 2) {
                    // one to one chat
                    eventChunks.forEach((chunk) => {
                        if (chunk.state_key !== this.myUserId) {
                            const findUser = find(this.displayStaffList, ['matrixUserId', chunk.state_key]);
                            if (findUser) {
                                findUser.roomExist = true;
                                findUser.roomId = roomId;
                            }
                        }
                    });
                } else {
                    // for group chat
                    const groupMembers = [];
                    const displayStaffList = cloneDeep(this.displayStaffList);
                    eventChunks.forEach((chunk) => {
                        if (chunk.state_key !== this.myUserId && chunk.membership !== 'leave') {
                            const matchStaffObj = find(displayStaffList, ['matrixUserId', chunk.state_key]);
                            if (matchStaffObj) {
                                matchStaffObj.isAlreadyMember = true; // for already added group members
                                groupMembers.push(matchStaffObj);
                            }
                        }
                    });
                    this.fetchGroupInfo(roomId, displayStaffList, groupMembers);
                }

            }, (error) => {
            });
    }

    fetchGroupInfo(roomId, displayStaffList, groupMembers): void {
        const uniqueId = Math.random() * (99 - 9999) + 99;
        this.chatService.getGroupName(roomId, this.accessToken)
            .then((response: any) => {
                const groupObj = {
                    groupName: response.name,
                    groupNameInput: response.name,
                    roomId,
                    uniqueId,
                    staffList: displayStaffList, // for add staff to group
                    chattingTo: new User(),
                    groupChat: true,
                    groupMembersList: groupMembers
                };
                const chatObj = new ChatWindow();
                Object.assign(chatObj, groupObj);
                this.displayGroupList.push(chatObj);
            }, (error) => {
            });
    }

    sendMessage(windowObj): void {
        const messageContent = windowObj.messageContent.split('\n').join('<br>');
        const windowAllMessages = windowObj.messages;
        const findMatchDateAllMsgObj = find(windowAllMessages, ['displayDate', 'today']); // message will always send on today
        if (findMatchDateAllMsgObj) {
            // chat is on going
            findMatchDateAllMsgObj.messageList.push({
                text: messageContent,
                messageTimeStamp: new Date().getTime(),
                displayDate: new Date(),
                type: 'sent'
            });
        } else {
            // first msg for today
            windowAllMessages.push({
                displayDate: 'today',
                messageList: [{
                    text: messageContent,
                    messageTimeStamp: new Date().getTime(),
                    displayDate: new Date(),
                    type: 'sent'
                }]
            });
        }
        setTimeout(() => {
            this.scrollChatWindowToBottom(windowObj);
        }, 0);
        this.chatService.sendRoomMessages(messageContent, windowObj.roomId, this.accessToken)
            .then((chatResponse: any) => {
                windowObj.messageContent = '';
            }, (error) => {
                windowObj.messageContent = '';
            });
    }

    /**
     * check is already chatting present or need to create a new room
     * @param staff staff object for which creating room
     */
    createRoom(staff): void {
        if (staff.roomExist) {
            // roome already exist
            this.openChatWindow(staff);

        } else {
            // create new room
            const obj = {
                is_direct: true,
                name: staff.username,
                invite: [staff.matrixUserId]
            };
            this.matrixClient.createRoom(obj, (err, data) => {
                if (err) {
                    return;
                }
                const roomId = data.room_id;
                const inviteId = obj.invite[0];
                const contentObj = {};
                contentObj[inviteId] = [data.room_id];
                const accountDataObj = {
                    content: contentObj,
                    type: 'm.direct'
                };
                // set direct chat
                this.matrixClient.setAccountData('m.direct', accountDataObj, (err1, data1) => {
                });

                // update staff list status
                const findUser = find(this.displayStaffList, ['matrixUserId', staff.matrixUserId]);
                if (findUser) {
                    findUser.roomExist = true;
                    findUser.roomId = roomId;
                }
                this.openChatWindow(staff);
            });
        }
    }

    /**
     * Open new chat window
     * @param staff: staff Object
     */
    openChatWindow(staff): void {
        const uniqueId = Math.random() * (99 - 9999) + 99;
        const displayStaffList = cloneDeep(this.displayStaffList);
        const matchStaffObj = find(displayStaffList, ['id', staff.id]);
        matchStaffObj.isAlreadyMember = true; // for already added group members
        // is this window opened?
        const openedWindow = this.chatWindows.find((windowObj) => windowObj.chattingTo.id === staff.id);
        if (!openedWindow) {
            const chatObj: any = {
                uniqueId,
                chattingTo: staff,
                roomId: staff.roomId,
                staffList: displayStaffList // for add staff
            };
            const newChatWindow = new ChatWindow();
            Object.assign(newChatWindow, chatObj);
            this.focusChatWindow(newChatWindow);
            this.getRoomMessages(newChatWindow, true);
        } else {
            this.focusChatWindow(openedWindow);
        }
    }

    /**
     * Augment messages chunks for Chat Window
     * @param messageChunks Messages chunks
     * @param newChatWindow Chat window object
     * @param isNewOpenWindow Is window already opened
     */
    renderMessages(messageChunks, newChatWindow, isNewOpenWindow: boolean): void {
        const messages = [];
        const allMessagesListObj = new Map();
        messageChunks.forEach((messageObj) => {
            if (messageObj.type === 'm.room.message' && messageObj.content.msgtype === 'm.text') {
                let augmentedMsgObj: any = {};
                const senderUserId = messageObj.sender;

                if (messageObj.sender !== this.myUserId) {
                    // msg receiving
                    const findUser = find(this.displayStaffList, ['matrixUserId', senderUserId]);
                    if (findUser) {
                        augmentedMsgObj = {
                            text: messageObj.content.body,
                            messageTimeStamp: messageObj.origin_server_ts,
                            displayDate: messageObj.origin_server_ts,
                            type: 'receive',
                            sender: findUser.firstName,
                            profilePic: findUser.profilePic

                        };
                    }
                } else {
                    // sent message
                    augmentedMsgObj = {
                        text: messageObj.content.body,
                        messageTimeStamp: messageObj.origin_server_ts,
                        displayDate: messageObj.origin_server_ts,
                        type: 'sent',
                        sender: ''
                    };
                }

                if (isToday(new Date(augmentedMsgObj.messageTimeStamp))) {
                    augmentedMsgObj.displayDate = 'today';
                } else if (isYesterday(new Date(augmentedMsgObj.messageTimeStamp))) {
                    augmentedMsgObj.displayDate = 'yesterday';
                } else {
                }
                Object.assign(messageObj, augmentedMsgObj);
                if (allMessagesListObj.has(messageObj.displayDate)) {
                    const messagesList = allMessagesListObj.get(messageObj.displayDate);
                    messagesList.push(augmentedMsgObj);
                } else {
                    allMessagesListObj.set(messageObj.displayDate, [messageObj]);
                }
            }
        });
        newChatWindow.messages = this.createMessageList(allMessagesListObj, newChatWindow.messages);
        this.manageWindows(isNewOpenWindow, newChatWindow, messageChunks);
    }

    /**
     * Find Space for new window if no space then splice the old one
     * @param isNewOpenWindow Is opening new window
     * @param newChatWindow New Chat window Object
     * @param messageChunks Messages Chunks List
     */
    manageWindows(isNewOpenWindow: boolean, newChatWindow: any, messageChunks: any): void {
        if (isNewOpenWindow) {
            setTimeout(() => { this.scrollChatWindowToBottom(newChatWindow); });
            // add the new chat window to the list of windows
            this.chatWindows.unshift(newChatWindow);
            // is there enough space left in the view port ?
            if (this.chatWindows.length * this.windowSizeFactor >= this.viewPortTotalArea - this.friendsListWidth)
             {
                this.chatWindows.pop();
            }
        } else {
            if (messageChunks.length) {
                // scroll the window manually so that user can again scroll top to 0
                const element = document.getElementById(newChatWindow.roomId);
                if(element){
                    element.scrollTop =200;
                }
            }
        }
    }


    createMessageList(messageListObj, availableMessages): Array<any> {
        messageListObj.forEach((messages, date) => {
            const dateAlreadyPresenceInList = find(availableMessages, ['displayDate', date]);
            if (dateAlreadyPresenceInList) {
                const unsortedMessageList = dateAlreadyPresenceInList.messageList.concat(messages);
                dateAlreadyPresenceInList.messageList = sortBy(unsortedMessageList, ['origin_server_ts']);
            } else {
                const messageObj = {
                    displayDate: date,
                    messageList: messages
                };
                availableMessages.push(messageObj);
            }
        });
        return availableMessages;
    }
      
    /**
     *  Scrolls a chat window message flow to the bottom
     */
    scrollChatWindowToBottom(windowObj: ChatWindow): void {
        const element = document.getElementById(windowObj.roomId);
        if(element){
            element.scrollTop = element.scrollHeight;
        }
        
    }

    /**
     * Sync API
     * @param nextBatchToken Next batch token for the next sync call
     */
    syncingOfData(nextBatchToken): void {
        this.chatService.getSyncRooms(this.accessToken, nextBatchToken)
            .then((response: any) => {
                this.nextBatchToken = response.next_batch;
                if (response.rooms.join) {
                    const roomIdsObj = response.rooms.join;
                    const inviteIdsObj = response.rooms.invite;
                    const presenceEvents = response.presence.events;
                    this.updateOnlinePresenceOfStaffs(presenceEvents);
                    this.updateRoomMessages(roomIdsObj);
                    this.autoJoinInvitedRooms(inviteIdsObj);
                    this.userIsTyping(roomIdsObj);
                }
                this.syncingOfData(this.nextBatchToken);
            }, (error) => {
            });
    }

    /**
     * If any user send any message then update messages list in the respective window
     */
    updateRoomMessages(roomIdsObj: any): void {
        for (const roomId in roomIdsObj) {
            if (roomIdsObj.hasOwnProperty(roomId)) {
                const matchChatWindow = find(this.chatWindows, ['roomId', roomId]);
                if (matchChatWindow) {
                    // update room details
                    const eventsList = roomIdsObj[roomId].timeline.events;
                    eventsList.forEach((event) => {
                        if (event.type === 'm.room.message' && event.sender !== this.myUserId) {
                            // msg receiving
                            const findUser = find(this.displayStaffList, ['matrixUserId', event.sender]);
                            if (findUser) {
                                const windowAllMessages = matchChatWindow.messages;
                                // message will always send on today
                                const findMatchDateAllMsgObj = find(windowAllMessages, ['displayDate', 'today']);
                                if (findMatchDateAllMsgObj) {
                                    // chat is on going
                                    findMatchDateAllMsgObj.messageList.push({
                                        text: event.content.body,
                                        messageTimeStamp: event.origin_server_ts,
                                        displayDate: event.origin_server_ts,
                                        type: 'receive',
                                        sender: findUser.firstName
                                    });
                                } else {
                                    // first msg for today
                                    windowAllMessages.push({
                                        displayDate: 'today',
                                        messageList: [{
                                            text: event.content.body,
                                            messageTimeStamp: event.origin_server_ts,
                                            displayDate: event.origin_server_ts,
                                            type: 'receive',
                                            sender: findUser.firstName
                                        }]
                                    });
                                }
                                matchChatWindow.typing = false;
                                setTimeout(() => {
                                    this.scrollChatWindowToBottom({ roomId } as any);
                                }, 0);
                            }
                        }
                    });
                }
            }
        }
    }

    /**
     * Receive typing event from the other user
     * @param roomIdsObj Current room object
     */
    userIsTyping(roomIdsObj: any): void {
        for (const roomId in roomIdsObj) {
            if (roomIdsObj.hasOwnProperty(roomId)) {
                const matchChatWindow = find(this.chatWindows, ['roomId', roomId]);
                if (matchChatWindow) {
                    // update room details
                    const eventsList = roomIdsObj[roomId].ephemeral.events;
                    eventsList.forEach((event) => {
                        matchChatWindow.typingUserNames = '';
                        if (event.type === 'm.typing' && event.content.user_ids && event.content.user_ids.length) {
                            const typingUsersIdsList = event.content.user_ids;
                            typingUsersIdsList.forEach((userId) => {
                                const findUser = find(this.displayStaffList, ['matrixUserId', userId]);
                                if (findUser) {
                                    matchChatWindow.typingUserNames = `${matchChatWindow.typingUserNames} ${findUser.firstName}`;
                                }
                            });
                            matchChatWindow.typing = true;
                        } else if (event.type === 'm.typing' && !event.content.user_ids.length) {
                            matchChatWindow.typing = false;
                        } else {
                            matchChatWindow.typing = false;
                        }
                    });
                }
            }
        }
    }

    /**
     * Send typing event to the server
     */
    sendEventUserIsTyping(event, roomId): void {
        this.matrixClient.sendTyping(roomId, true, 1000, (err, data) => {
            console.log('==err data', err, data);
        });
    }

    /**
     * Closes a chat window via the close 'X' button
     * @param windowObj Current chat window object
     */
    onCloseChatWindow(windowObj: ChatWindow): void {
        const index = this.chatWindows.indexOf(windowObj);
        this.chatWindows.splice(index, 1);
    }

    minimizeMaximize(index): void {
        const chatWindow = this.chatWindows[index];
        chatWindow.minimize = !chatWindow.minimize;
    }

    detectScroll(windowObj): void {
        const element = document.getElementById(windowObj.roomId);
        if (element.scrollTop === 0) {
            // almost reached at the top of the window
            this.getRoomMessages(windowObj, false);
        }
    }

    getRoomMessages(chatWindow, isNewOpenWindow: boolean): void {
        this.chatService.getRoomMessages(chatWindow.roomId, chatWindow.fromToken, this.accessToken)
            .then((chatResponse: any) => {
                chatWindow.fromToken = chatResponse.end;
                const sortedMessageChunks = sortBy(chatResponse.chunk, ['origin_server_ts']);
                this.renderMessages(sortedMessageChunks, chatWindow, isNewOpenWindow);
            }, (error) => {
            });
    }

    isEnterPressed(event, windowObj): void {
        const key = event.which || event.keyCode;
        if (key === 13 && !event.shiftKey) {
            this.sendMessage(windowObj);
        } else if (key === 13 && event.shiftKey) {
            if (event.type === 'keydown') {
                windowObj.messageContent = `${windowObj.messageContent}\n`;
            }
           
            event.preventDefault();
        }
    }

    focusChatWindow(windowObj): void {
        for (const chatWindow of this.chatWindows) {
            chatWindow.focus = false;
        }
        windowObj.focus = true;
    }

    selectStaffForGroupChat(staff): void {
        if (!staff.isAlreadyMember) {
            staff.selected = staff.selected ? false : true;
        }
    }

    createGroup(windowObj): void {
        const selectedStaffList = filter(windowObj.staffList, ['selected', true]);
        const selectedStaffsUserIdList = map(selectedStaffList, 'userId');
        selectedStaffsUserIdList.push(windowObj.chattingTo.userId);
        if (windowObj.groupChat) {
            // add members to the already added group
            this.addMembersToGroup(windowObj, selectedStaffsUserIdList);
        } else {
            // create a new group
            this.createGroupChat(windowObj, selectedStaffsUserIdList);
        }
    }

    createGroupChat(windowObj, selectedStaffsUserIdList): void {
        windowObj.groupName = windowObj.groupNameInput;
        const obj = {
            is_direct: true,
            name: windowObj.groupName,
            invite: selectedStaffsUserIdList
        };
        this.matrixClient.createRoom(obj, (err, roomObj) => {
            if (err) {
                return;
            }
            const roomId = roomObj.room_id;
            this.createGroupChatWindow(obj, roomId, selectedStaffsUserIdList);
        });
    }

    fetchGroupChat(groupObj): void {
        const removedStaffIndex = findIndex(this.chatWindows, ['roomId', groupObj.roomId]);
        if (removedStaffIndex === -1) {
            this.getRoomMessages(groupObj, true);
        } else {
            // window already opened
            this.getRoomMessages(groupObj, false);
        }
        this.focusChatWindow(groupObj);
        // open chat window
    }

    /**
     * create new group ChatWindow with the selected staffs
     */
    createGroupChatWindow(groupObj, roomId, selectedStaffsUserIdList): void {
        const uniqueId = Math.random() * (99 - 9999) + 99;
        const displayStaffList = cloneDeep(this.displayStaffList);
        const groupMembers = [];
        selectedStaffsUserIdList.forEach((userId) => {
            const matchStaffObj = find(displayStaffList, ['userId', userId]);
            if (matchStaffObj) {
                matchStaffObj.isAlreadyMember = true;
                groupMembers.push(matchStaffObj);
            }
        });
        // is this window opened?
        const openedWindow = this.chatWindows.find((windowObj) => windowObj.roomId === roomId);
        if (!openedWindow) {
            const chatObj: any = {
                uniqueId,
                chattingTo: new User(),
                roomId,
                staffList: displayStaffList, // for add staff
                groupName: groupObj.name,
                groupNameInput: groupObj.name,
                groupChat: true,
                groupMembersList: groupMembers
            };
            const newChatWindow = new ChatWindow();
            Object.assign(newChatWindow, chatObj);
            this.displayGroupList.push(newChatWindow);
            this.focusChatWindow(newChatWindow);
            this.getRoomMessages(newChatWindow, true);
        } else {
            this.focusChatWindow(openedWindow);
        }
    }

    /**
     * remove staff From Group
     */
    removeFromGroup(windowObj: any, staffObj): void {
        this.chatService.removeUserFromGroup(staffObj.userId, windowObj.roomId, this.accessToken)
            .then((chatResponse: any) => {
                // remove user from list
                const groupMembersList = windowObj.groupMembersList;
                const removedStaffIndex = findIndex(groupMembersList, ['userId', staffObj.userId]);
                if (removedStaffIndex !== -1) {
                    groupMembersList.splice(removedStaffIndex, 1);
                    const staffList = windowObj.staffList;
                    const staff = find(staffList, ['userId', staffObj.userId]);
                    staff.isAlreadyMember = false;
                }
            }, (error) => {
            });
    }

    addMembersToGroup(windowObj, selectedStaffsUserIdList): void {
        selectedStaffsUserIdList.forEach((userId) => {
            this.chatService.addUserToGroup(userId, windowObj.roomId, this.accessToken)
                .then((chatResponse: any) => {
                    // add user to list
                    const staffList = windowObj.staffList;
                    const matchStaffObj = find(staffList, ['userId', userId]);
                    if (matchStaffObj) {
                        matchStaffObj.isAlreadyMember = true;
                        matchStaffObj.selected = false;
                        const groupMembersList = windowObj.groupMembersList;
                        const staffObj = cloneDeep(matchStaffObj);
                        groupMembersList.push(staffObj);
                    }
                }, (error) => {
                });
        });
    }

    changeNameOfGroup(chatWindowObj: any, roomId: string): void {
        chatWindowObj.groupName = chatWindowObj.groupNameInput;
        console.log('===room', roomId, chatWindowObj.groupNameInput, chatWindowObj.groupName);
        this.chatService.updateGroupName(chatWindowObj.groupName, roomId, this.accessToken)
            .then((chatResponse: any) => {
            }, (error) => {
            });
    }

    deselectSelectedStaff(chatWindowObj): void {
        const staffList = chatWindowObj.staffList;
        chatWindowObj.popperOpen = false;
        staffList.forEach((staffObj) => {
            staffObj.selected = false;
        });
    }

    addClassToButton(chatWindowObj): void {
        chatWindowObj.popperOpen = true;
    }
    // tslint:disable-next-line:max-file-line-count

    minimizeMaximizeChat() {
        this.isChatOpened = !this.isChatOpened;
    }
}
