import {
    trigger, state, style, transition,
    animate, group, query, stagger, keyframes
} from '@angular/animations';

export const SlideInOutAnimation = [
    trigger('rightSlideInOut', [
        state('in', style({
            'right': '0'
        })),
        state('out', style({
            'right': '-101%'
        })),
        transition('in => out', [group([
            animate('1s ease-in-out', style({
                'right': '-101%'
            }))
        ])]),
        transition('out => in', [group([
            animate('1s ease-in-out', style({
                'right': '0'
            }))
        ])])
    ]),
    trigger('topSlideInOut', [
        state('in', style({
            'top': '0'
        })),
        state('out', style({
            'top': '-100%'
        })),
        transition('in => out', [group([
            animate('1s ease-in-out', style({
                'top': '-100%'
            }))
        ])]),
        transition('out => in', [group([
            animate('1s ease-in-out', style({
                'top': '0'
            }))
        ])])
    ]),
];
