import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule, routing } from './app-routing.module';
import { AppComponent } from './app.component';
// import { MatVideoModule } from 'mat-video';
import { ToastrModule } from 'ngx-toastr';
import { HttpModule } from '@angular/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPopperModule } from 'ngx-popper';
import { OrderModule } from 'ngx-order-pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragDropModule as primeDrop } from 'primeng/dragdrop';
import { SvgIconsDirective } from './shared/directive/svg-icon-directive';
import { SingleKeySearchPipe } from './shared/directive/single-search-directive';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatCheckboxModule,
  MatIconModule,
  MatProgressBarModule,
  MatTreeModule,
  MatToolbarModule,
  MatTooltipModule } from '@angular/material';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
// import { AngularDraggableModule } from 'angular2-draggable';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NbThemeModule, NbSidebarModule, NbLayoutModule, NbSidebarService } from '@nebular/theme';
import { HeaderComponent } from './header/header.component';
import { SubnavComponent } from './subnav/subnav.component';
import { CompanyComponent } from './company/company.component';
import { FactoryComponent } from './factory/factory.component';
// import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { CustomMaterialModule } from './core/material.module';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RolesComponent } from './roles/roles.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { UsersComponent } from './users/users.component';
import { StylesComponent } from './styles/styles.component';
import { ProComponent } from './pro/pro.component';
import { HttpclientService } from './shared/httpclient.service';
// import { ChartComponent } from './chart/chart.component';
import { FormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { UiSwitchModule } from 'ngx-ui-switch';
import { LicenseComponent } from './license/license.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { ChatComponent } from './chat/chat.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerAndSupplierComponent } from './customer-and-supplier/customer-and-supplier.component';
import { OperationsComponent } from './operations/operations.component';
import { TableModule } from 'primeng/table';
import { MachineComponent } from './machine/machine.component';
import { SubsectionComponent } from './subsection/subsection.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ThreadConsumptionComponent } from './thread-consumption/thread-consumption.component';
import { SequenceComponent } from './sequence/sequence.component';
import { OrderCreationComponent } from './order-creation/order-creation.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { DropdownModule } from 'primeng/dropdown';
import { CopyOperationsComponent } from './copy-operations/copy-operations.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DialogModule } from 'primeng/dialog';
import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { TabsModule } from 'ngx-tabset';
import { FeedbackDirective } from './feedback/feedback.directive';
import { FeedbackService } from './feedback/feedback.service';
import { FeedbackDialogComponent } from './feedback/feedback-dialog/feedback-dialog.component';
import { FeedbackModule } from './feedback/feedback.module';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { SharedService } from './shared/shared.service';
import { FeedBackComponent } from './feed-back/feed-back.component';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import {PaginatorModule} from 'primeng/paginator';
import { TranslateModule } from '@ngx-translate/core';
import { MyTranslateModule } from './my-translate/my-translate.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SubnavComponent,
    CompanyComponent,
    FactoryComponent,
    SvgIconsDirective,
    SingleKeySearchPipe,
    LoginComponent,
    RolesComponent,
    UsersComponent,
    StylesComponent,
    ProComponent,
    // ChartComponent,
    LicenseComponent,
    ChatComponent,
    DashboardComponent,
    CustomerAndSupplierComponent,
    OperationsComponent,
    MachineComponent,
    SubsectionComponent,
    ThreadConsumptionComponent,
    SequenceComponent,
    OrderCreationComponent,
    SpinnerComponent,
    CopyOperationsComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    RecoverPasswordComponent,
    FeedBackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // NbThemeModule.forRoot({ name: 'default' }),
    // NbLayoutModule,
    RouterModule,
    HttpClientModule,
    PinchZoomModule,
    AmChartsModule,
    HttpModule,
    CdkTableModule,
    CdkTreeModule,
    UiSwitchModule,
    AngularFontAwesomeModule,
    MatCheckboxModule,
    ToastrModule.forRoot({
      timeOut: 1900,
      preventDuplicates: true
    }),
    DatePickerModule,
    DialogModule,
    PaginatorModule,
    AccordionModule,
    TabsModule.forRoot(),
    // TreeViewModule,
    // MatExpansionModule,
    // MatGridListModule,
    MatIconModule,
    MatProgressBarModule,
    ColorPickerModule,
    MatTreeModule,
    TreeModule,
    ReactiveFormsModule,
    // MatVideoModule,
    // CustomMaterialModule,
    // NbSidebarModule,
    NgxSmartModalModule.forRoot(),
    FormsModule,
    // AngularFontAwesomeModule,
    // BootstrapModalModule,
    routing,
    DragDropModule,
    NgbModule,
    OrderModule,
    NgxPopperModule,
    Ng2SearchPipeModule,
    AutoCompleteModule,
    TooltipModule,
    TableModule,
    FileUploadModule,
    primeDrop,
    DropdownModule,
    MatToolbarModule,
    MatTooltipModule,
    FeedbackModule,
    Ng5SliderModule,
    NgxImageZoomModule.forRoot(),
    TranslateModule,
    MyTranslateModule
  ],
  providers: [
    // NbSidebarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpclientService,
      multi: true
    },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
    FeedbackService,
    SharedService
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule,MyTranslateModule, CdkTableModule, CdkTreeModule, ColorPickerModule, SvgIconsDirective, SingleKeySearchPipe, MatProgressBarModule,
    FeedbackDirective, MatToolbarModule, MatTooltipModule, FeedbackModule],
  entryComponents: [FeedbackDialogComponent]
})
export class AppModule { }
