import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  constructor(
    private http: HttpClient,
    private httpClientService: HttpclientService,
    private httpService: HttpClient
  ) { }

  getFactoryId() {
    const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
    return this.httpClientService.get(url);
  }

  getRoles(companyId) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/get/roles?companyId=${companyId}`;
    return this.httpClientService.get(url);
  }
  getModules() {
    const url = `${window.location.origin}/apigateway/admin/api/v1/get/module/submodules`;
    return this.httpClientService.get(url);
  }

  getRolesPermission(roleId) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/get/rolePermissions?roleId=${roleId}`;
    return this.httpClientService.get(url);
  }

  saveRolesPermission(data) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/save/rolePermissions`;
    return this.httpClientService.post(url, data);
    // apigateway/admin/api/v1/save/rolePermissions
  }

  updateRolesPermission(data) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/update/rolePermissions`;
    return this.httpClientService.put(url, data);
  }

  deleteRole(roleId) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/delete/roles?roleId=${roleId}`;
    return this.httpClientService.remove(url, {});
  }
}
