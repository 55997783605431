const threadConsumptionColumns = [
    { field: 'operation_number', header: 'THREAD_CONSUMPTION.OP_NO' },
    { field: 'description', header: 'THREAD_CONSUMPTION.DESCRIPTION' },
    { field: 'std_time', header: 'THREAD_CONSUMPTION.Std_MIN' },
    { field: 'machine', header: 'THREAD_CONSUMPTION.MACHINE' },
    { field: 'seam_length', header: 'THREAD_CONSUMPTION.SEAM_LEAGTH' },
    { field: 'totalthread', header: 'THREAD_CONSUMPTION.TOTAL_THREAD' },
    { field: 'upperthread', header: 'THREAD_CONSUMPTION.UPPER_THREAD' },
    { field: 'looperthread', header: 'THREAD_CONSUMPTION.LOOPER_THREAD' },
    { field: 'thread_quality', header: 'THREAD_CONSUMPTION.THREAD_QUALITY' },
    { field: 'stitchclass', header: 'THREAD_CONSUMPTION.STITCH_CLASS' },
    { field: 'needlethreadratio', header: 'THREAD_CONSUMPTION.NEEDLE_THREAD' },
    { field: 'looperthreadratio', header: 'THREAD_CONSUMPTION.LOOPER_THREAD' },
    { field: 'needlecount', header: 'THREAD_CONSUMPTION.NO_OF_NEEDLE' },
];
const OrderthreadConsumptionColumns = [
    { field: 'order_number', header: 'ORDER_THREAD_CONSUMPTION.ORDER_NO' },
    { field: 'color_code', header: 'ORDER_THREAD_CONSUMPTION.COLOR' },
    { field: 'order_quantity', header: 'ORDER_THREAD_CONSUMPTION.QUANTITY' },
    { field: 'totalcms', header: 'ORDER_THREAD_CONSUMPTION.TOTAL_CM' },
    { field: 'ppm', header: 'ORDER_THREAD_CONSUMPTION.Total_METER' },
    { field: 'allowance', header: 'ORDER_THREAD_CONSUMPTION.METER_ALLOWANCE' },
    { field: 'totalms', header: 'ORDER_THREAD_CONSUMPTION.THREAD_METER' },
    { field: 'thread_quality', header: 'ORDER_THREAD_CONSUMPTION.THREAD_QUALITY' }
];
const machineThreadCols = [
    { field: 'name', header: 'MACHINE_THREAD_CONSUMPTION.MACHINE'},
    { field: 'thread_quality', header: 'MACHINE_THREAD_CONSUMPTION.THREAD'}
];
export { machineThreadCols };
export {threadConsumptionColumns};
export {OrderthreadConsumptionColumns};
