import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SubsectionService {
    constructor(
        private http: HttpClient,
        private httpClientService: HttpclientService,
        private httpService: HttpClient
    ) { }

    /**
     * get factory id of logged in user
     */
    getFactoryId() {
        const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
        return this.httpClientService.get(url);
    }

    /**
     * get Stages from server
     */
    getStages() {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/stages`;
        return this.httpClientService.get(url);
    }

    /**
     * search for avail styles
     */
    searchStyle(styleName, factoryId) {
        const listOfStyle = this.httpService
            .get(`apigateway/style/api/v1/auto/complete/style?styleName=${styleName}&factoryId=${factoryId}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Style Name': 'No Style Found' } as any];
                })
            );
        return listOfStyle;
    }

    /**
     * get parts List on based on style 
     */
    getparts(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/subsection/part?styleId=${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * create subsection
     */
    createSubsection(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/subsection/part`;
        return this.httpClientService.post(url, data);
    }

    /**
     * get op seq
     */
    getOPSeq(styleId, partId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operation/sequences/style/part?styleId=
        ${styleId}&partsId=${partId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * get uploaded images
     */
    getImages(subId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/image/subsection?subsectionId=${subId}`;
        return this.httpClientService.get(url);
    }

    /**
     * copy operation sequence list to another style
     */
    copySequenceList(tostyleId, selectedList, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/copy/operation/sequences/part?toStyleId=
        ${tostyleId}&stageId=${stageId}`;
        return this.httpClientService.post(url, selectedList);
    }

    /**
     * get sequence of selected styleId
     * @param styleId selected style
     */
    getSequence(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operations/style?styleId=${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }
}
