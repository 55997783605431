import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable()

export class DashboardService {
    constructor(
        private http: HttpClient,
        private httpClientService: HttpclientService,
        private httpService: HttpClient
    ) { }

    getCardsDetails(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/cardData?factoryId=${data.factoryId}&type=${data.type}`;
        return this.httpClientService.get(url);
    }
    getProSMV(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/operation/totalApproved?factoryId=
        ${data.factoryId}&type=${data.type}`;
        return this.httpClientService.get(url);
    }
    getProCostSAM(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/getTotal/costing/manual?factoryId=
        ${data.factoryId}&type=${data.type}`;
        return this.httpClientService.get(url);
    }

    getProductionCosting(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/getTotal/production/costing?factoryId=
        ${data.factoryId}&year=${data.year}`;
        return this.httpClientService.get(url);
    }
    getUserWiseData(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/getUserWiseMethodImprovement?factoryId=
        ${data.factoryId}&type=${data.type}`;
        return this.httpClientService.get(url);
    }
    getApprvedLastData(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/getTotal/approved/unApproved?factoryId=${data.factoryId}`;
        return this.httpClientService.get(url);
    }
    getstageWiseData(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/stageWiseAnalysis?factoryId=${data.factoryId}&type=${data.type}`;
        return this.httpClientService.get(url);
    }
    getStyleWiseData(data) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/styleWiseAnalysis?factoryId=${data.factoryId}&type=${data.type}`;
        return this.httpClientService.get(url);
    }
    getCardData(data,cardNo) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/cardData/details?factoryId=${data.factoryId}&type=${data.type}&cardNo=${cardNo}`;
        return this.httpClientService.get(url);
    }
}
