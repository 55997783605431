import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable({
  providedIn: 'root'
})

export class MachineService {

  constructor(private http: HttpClient, private httpClientService: HttpclientService, private httpService: HttpClient) { }

  getMachine() {
    const url = `${window.location.origin}/apigateway/style/api/v1/machines/user`;
    return this.httpClientService.get(url);
  }

  createMachine(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/machine`;
    return this.httpClientService.post(url, data);
  }

  createStitch(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/stitchClass`;
    return this.httpClientService.post(url, data);
  }

  updateMachine(data, machineId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/update/machine?machineId=` + machineId;
    return this.httpClientService.put(url, data);
  }

  createBrand(data, customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/add/brand?customerId=` + customerId;
    return this.httpClientService.post(url, data);
  }

  getBrands(customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/get/allBrands?customerId=` + customerId;
    return this.httpClientService.get(url);
  }

  getFactoryId() {
    const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
    return this.httpClientService.get(url);
  }

  getStitchClass() {
    const url = `${window.location.origin}/apigateway/style/api/v1/stitchClasses/user`;
    return this.httpClientService.get(url);
  }

  companySearch(companyName: string) {
    const url = `${window.location.origin}/apigateway/style/api/v1/auto/complete/company?companyName=`;
    return this.httpClientService.get(url);
  }

  statusUser(userId: number, status: boolean) {
    const url = `${window.location.origin}/apigateway/style/api/v1/set/user/status?userId=${userId}&active=${status}`;
    return this.httpClientService.get(url);
  }

  deleteMachine(machineId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/delete/machine?machineId=` + machineId;
    return this.httpClientService.remove(url, machineId);
  }
  deleteStichClass(stitchClassId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/delete/stitchClass?stitchClassId=${stitchClassId}`;
    return this.httpClientService.remove(url, {});
  }
  updateStitch(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/update/stitchClass`;
    return this.httpClientService.put(url, data);
  }
}
