import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'singleKeySearch'
})

export class SingleKeySearchPipe implements PipeTransform {

    constructor() { }

    createRegExpFromString(data, ignoreCase = false): RegExp {
        data = this.regExpEscaper(data);
        const regExp = ignoreCase ? new RegExp(data) : new RegExp(data, 'i');
        return regExp;
    }

    regExpEscaper(data): string {
        return data.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    // changes during aot compilation dataList:Array<any> to any
    transform(dataList, dataKey, inputValue: string, ignoreCase: boolean = false): any {
        if (!inputValue) {
            return dataList;
        }
        inputValue = inputValue ? inputValue.trim() : null;
        const inputValueRegExp = this.createRegExpFromString(inputValue, ignoreCase);
        // Empty Input's field
        if (!inputValue) {
            return dataList;
        } else {
            return dataList.filter(dataObj => {
                const dataValue = dataObj[dataKey];
                return inputValueRegExp.test(dataValue);
            });
        }
    }

}
