const sequenceTableCol = [
    { field: 'description', header: "OPERATION_SEQUENCE.DESCRIPTION" }, 
    { field: 'operation_number', header: "OPERATION_SEQUENCE.OP_NUMBER" }, 
    { field: 'std_time', header: "OPERATION_SEQUENCE.STD_TIME" } 
];
const sequenceTableAllCol = [
    { field: 'description', header: "OPERATION_SEQUENCE.DESCRIPTION" }, 
    { field: 'operation_number', header: "OPERATION_SEQUENCE.OP_NUMBER" }, 
    { field: 'std_time', header: "OPERATION_SEQUENCE.STD_TIME" }, 
    { field: 'subSection', header: "OPERATION_SEQUENCE.SUB_SECTION" } 
];

const copySequenceCol = [
    { field: 'seqNo', header: "OPERATION_SEQUENCE.SEQ_NO" }, 
    { field: 'description', header: "OPERATION_SEQUENCE.DESCRIPTION" }, 
    { field: 'std_time', header: "OPERATION_SEQUENCE.STD_MIN" }, 
    { field: 'subSection', header: "OPERATION_SEQUENCE.SUB_SECTION" }, 
    { field: 'ppName', header: "OPERATION_SEQUENCE.PROGRESS_POINT" },
    { field: 'operation_number', header: "OPERATION_SEQUENCE.OP_NUMBER" }, 
    { field: 'group', header: "OPERATION_SEQUENCE.GROUP" }, 
    { field: 'remark', header: "OPERATION_SEQUENCE.REMARK" } 
];
const versionCols = [
    { field: 'seqNo', header: "OPERATION_SEQUENCE.SEQ" },
    { field: 'description', header: "OPERATION_SEQUENCE.DESCR" }, 
    { field: 'operation_number', header: "OPERATION_SEQUENCE.OP_NO" }, 
    { field: 'subSection', header: "OPERATION_SEQUENCE.SUB_NAME" }, 
    { field: 'ppName', header: "OPERATION_SEQUENCE.PP_NAME" },
    { field: 'group', header: "OPERATION_SEQUENCE.GROUP_NAME" } 
];

export { sequenceTableCol };
export { sequenceTableAllCol };
export { copySequenceCol };
export { versionCols };
