import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private httpClientService: HttpclientService,
    private httpService: HttpClient
  ) {}

  getUser() {
    const url = `${window.location.origin}/apigateway/admin/api/v1/get/users`;
    return this.httpClientService.get(url);
  }

  createUser(data) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/register/user`;
    return this.httpClientService.post(url, data);
  }

  updateUser(data) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/update/user`;
    return this.httpClientService.put(url, data);
  }

  companySearch(companyName: string) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/auto/complete/company?companyName=`;
    return this.httpClientService.get(url);
  }

  statusUser(userId: number, status: boolean) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/set/user/status?userId=${userId}&active=${status}`;
    return this.httpClientService.post(url, {});
  }

  search(companyName) {
    var listOfCompany = this.httpService
      .get('/apigateway/admin/api/v1/auto/complete/company?companyName=' + companyName)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length !== 0 ? (data as any[]) : [{ 'Company Name': 'No Company Found' } as any];
        })
      );
    return listOfCompany;
  }

  searching(roleName: any, companyId: any) {
    var listOfrole = this.httpService
      .get(`/apigateway/admin/api/v1/auto/complete/company/role?roleName=${roleName}&companyId=${companyId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length !== 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
        })
      );
    return listOfrole;
  }

  searchFactory(factoryName: any, companyId: any) {
    // tslint:disable-next-line:max-line-length
    var listOfFactory = this.httpService
      .get(`/apigateway/admin/api/v1/auto/complete/factory?factoryName=${factoryName}&companyId=${companyId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length !== 0 ? (data as any[]) : [{ 'factory Name': 'No factory Found' } as any];
        })
      );
    return listOfFactory;
  }

  deleteUser(userId) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/delete/user?userId=` + userId;
    return this.httpClientService.remove(url, userId);
  }

  /**
   * switch user
   */
  switchUser(userName) {
    const url = `${window.location.origin}/apigateway/login/api/v1/user/switchUser?username=${userName}`;
    return this.httpClientService.put(url, {});
  }
}
