import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable()

export class HeaderService {
    constructor(
        private http: HttpClient,
        private httpClientService: HttpclientService,
        private httpService: HttpClient
    ) { }

    getFactory() {
        const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
        return this.httpClientService.get(url);
    }

}
