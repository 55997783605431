import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpclientService } from '../shared/httpclient.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.sass']
})
export class ForgetPasswordComponent implements OnInit {
  forgetUserName: string;
  result: any = {};
  spinner = false;

  constructor(
    public httpClientService: HttpclientService,
    public router: Router,
    private toaster: ToastrService) { }

  ngOnInit() {
  }

  forget() {
    this.spinner = true;
    this.forgetUser(this.forgetUserName).subscribe((response: any) => {
      this.spinner = false;
      this.toaster.success(response.message, 'Success');
      this.router.navigate(['/recoverPassword']);
    }, error => {
      this.spinner = false;
      this.toaster.error(error.error.message, 'Unauthorised');
    });
  }

  forgetUser(userName): Observable<any[]> {
    const url = `${window.location.origin}/apigateway/login/api/v1/user/forgotpassword?username=${userName}`;
    return this.httpClientService.put(url, {});
  }

}
