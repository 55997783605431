const operationsColumns = [
    { field: 'opNo', header: 'OPERATION.OP_NO' },
    { field: 'desc', header: 'OPERATION.DESCRIPTION' },
    { field: 'machine', header: 'OPERATION.MACHINE' },
    { field: 'sLength', header: 'S. L.' },
    { field: 'stdMin', header: 'SMV' },
    { field: 'workAid', header: 'OPERATION.WORK_AID' },
    { field: 'status', header: 'OPERATION.STATUS' },
    { field: 'group', header: 'OPERATION.GRADE' },
    { field: 'skill', header: 'OPERATION.SKILL' },
    { field: 'action', header: 'OPERATION.ACTION' },
    { field: 'material', header: 'OPERATION.MATERIAL' },
    { field: 'skill', header: 'OPERATION.PRODUCT' },
    { field: 'skill', header: 'OPERATION.PART' },
    { field: 'grade', header: 'OPERATION.OP_TYPE' },
    // { field: 'createdBy', header: 'CreatedBy' }
];

export {operationsColumns};
