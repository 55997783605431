import { Component, OnInit, Injectable, ViewChild, } from '@angular/core';
import { SubsectionService } from './subsection.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { file04 } from './subsection';
import * as cloneDeep from 'lodash/cloneDeep';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../shared/shared.service';


@Component({
  selector: 'app-subsection',
  templateUrl: './subsection.component.html',
  styleUrls: ['./subsection.component.sass'],
  // providers: [ChecklistDatabase]
})
export class SubsectionComponent implements OnInit {

  spinner: boolean;
  factoryId: number;
  stlyeOptions: Array<any>;
  availablestyleList: Array<any>;
  styleId: number;
  copyStlyeOptions: Array<any>;
  availablecopyStyleList: Array<any>;
  copyStyleId: number;
  createSubsectionData: any = {};
  filesTree4: Array<any> = [];
  selectedFiles2: Array<any> = [];
  subsectionId: Array<any> = [];
  selectedParts: Array<any> = [];
  imageUploadUrl: string;
  sequenceList: Array<any> = [];
  checkedData: Array<any> = [];
  uploadedImages: Array<any> = [];
  copiedImage: any;
  copiedImages: Array<any> = [];
  copiedSequenceList: Array<any> = [];
  factoryList: Array<any> = [];
  stageList: Array<any> = [];
  stageId: number;
  subAuthData: any = {};
  copyStageId: any;

  constructor(
    public subsectionService: SubsectionService,
    public httpClient: HttpClient,
    private toastr: ToastrService,
    public sharedService: SharedService,
    public ngxSmartModalService: NgxSmartModalService) {
  }

  ngOnInit() {
    this.getFactoryId();
    this.getStages();
    this.getAuthData();
  }

  /**
   * fetch role and permissions
   */
  getAuthData() {
    this.spinner = true;
    const auth = this.sharedService.authData$.subscribe((data: any) => {
      const authData = data.find((el) => {
        return el.name === 'Operation';
      });
      const subAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'Subsection';
      });
      this.subAuthData = subAuthData;
      this.spinner = false;
    });
  }

  dragStart(event, item) {
    this.copiedImage = item;
  }

  drop(event) {
    if (this.copyStyleId) {
      this.copiedImages.push(this.copiedImage);
      const opIds = this.sequenceList.map(x => x.id);
      this.copyOPSequence(opIds);
    } else {
      this.toastr.error('please Select Copy Style', 'Error');
    }
  }

  dragEnd(event) {
  }

  /**
   * select all parts and subsection
   */
  selectall(clicked) {
    if (clicked) {
      for (const list of this.filesTree4) {
        for (const subList of list.subsectionParts) {
          list.checked.push(subList.id);
        }
      }
    } else {
      for (const list of this.filesTree4) {
        list.checked = [];
      }
    }
  }

  /**
   * select part
   */
  selectPart(clicked, partId) {
    if (clicked) {
      this.selectedParts.push(partId);
    } else {
      this.selectedParts.splice(this.selectedParts.indexOf(partId), 1);
    }
  }

  /**
   * fetch factory id of logged in user
   */
  getFactoryId() {
    this.spinner = true;
    this.subsectionService.getFactoryId().subscribe((response: any) => {
      this.factoryList = response.data;
      this.factoryId = response.data[0].id;
      this.spinner = false;
    });
  }

  /**
   * fetch stages
   */
  getStages() {
    this.spinner = true;
    this.subsectionService.getStages().subscribe((response: any) => {
      this.stageList = response.data;
      this.stageId = this.stageList[0].id;
      this.copyStageId = this.stageList[0].id;
    });
  }

  /**
   * while searching for available operations
   * @param event string to be searched
   */
  searchstyle(event) {
    this.subsectionService.searchStyle(event.query, this.factoryId).subscribe((response: any) => {
      const operationList = [];
      for (const op of response.data) {
        // comp.name = comp.name;
        operationList.push(op.name);
      }
      this.stlyeOptions = operationList.length > 0 ? operationList : ['No Data Found'];
      this.availablestyleList = response.data;
    });
  }

  /**
   * capture the id of selected operations
   * @param event selected operation from options
   */
  captureStyleId(event: any) {
    for (const list of this.availablestyleList) {
      if (list.name === event) {
        this.styleId = list.id;
      }
    }
    this.getPartList();
  }

  /**
   * get parts of selected style
   */
  getPartList() {
    this.spinner = true;
    this.selectedParts = [];
    this.uploadedImages = [];
    this.sequenceList = [];
    this.subsectionService.getparts(this.styleId, this.stageId).subscribe((response: any) => {
      this.filesTree4 = response.data;
      for (const file of this.filesTree4) {
        file.label = file.name;
        file.children = file.subsectionParts;
        file.checked = [];
        for (const subFile of file.children) {
          subFile.label = subFile.name;
        }
      }
      this.selectedFiles2 = this.filesTree4;
      this.spinner = false;
    });
  }

  /**
   * while searching for available copy style options
   * @param event string to be searched
   */
  copysearchstyle(event) {
    this.subsectionService.searchStyle(event.query, this.factoryId).subscribe((response: any) => {
      const operationList = [];
      for (const op of response.data) {
        // comp.name = comp.name;
        operationList.push(op.name);
      }
      this.copyStlyeOptions = operationList.length > 0 ? operationList : ['No Data Found'];
      this.availablecopyStyleList = response.data;
    });
  }

  /**
   * capture the id of selected copy style
   * @param event selected copy style from options
   */
  copyCaptureStyleId(event: any) {
    for (const list of this.availablecopyStyleList) {
      if (list.name === event) {
        this.copyStyleId = list.id;
      }
    }
    this.getCopiedSequence();
  }

  /**
   * open subsection
   */
  openSubsection() {
    this.ngxSmartModalService.getModal('categoryModal').open();
  }

  /**
   * create subsection
   */
  createSubsection() {
    this.spinner = true;
    this.createSubsectionData.partsId = this.selectedParts[0];
    this.subsectionService.createSubsection(this.createSubsectionData).subscribe((response: any) => {
      this.spinner = false;
      this.getPartList();
    }, error => {
      this.spinner = false;
    });
  }

  /**
   * change subsection list
   */
  changeSubsection(parentId, nodeId) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.filesTree4.length; i++) {
      if (this.filesTree4[i].id === parentId) {
        if (!(this.filesTree4[i].checked.indexOf(nodeId) > -1)) {
          this.filesTree4[i].checked.push(nodeId);
          // if (this.filesTree4[i].checked.length === 1) {
          this.getOPSequence(parentId, true);
          // }
        } else {
          this.filesTree4[i].checked.splice(this.filesTree4[i].checked.indexOf(nodeId), 1);
          // if (this.filesTree4[i].checked.length === 1) {
          this.getOPSequence(parentId, false);
          // }
        }
        // break;
      }
    }
    if (this.selectedParts.indexOf(parentId) > -1) {
      this.selectedParts.splice(this.selectedParts.indexOf(parentId), 1);
    } else {
      this.selectedParts.push(parentId);
    }
    if (this.subsectionId.indexOf(nodeId) > -1) {
      this.subsectionId.splice(this.subsectionId.indexOf(nodeId), 1);
      this.getUploadedImage(nodeId, parentId, false);
    } else {
      this.subsectionId.push(nodeId);
      this.getUploadedImage(nodeId, parentId, true);
    }
  }

  /**
   * get OP sequences of selected parts
   */
  getOPSequence(partId, isNew) {
    this.spinner = true;
    this.subsectionService.getOPSeq(this.styleId, partId, this.stageId).subscribe((response: any) => {
      this.spinner = false;
      if (isNew) {
        this.sequenceList = [...this.sequenceList, ...response.data];
      } else {
        const seqLength = JSON.parse(JSON.stringify(this.sequenceList.length));
        for (let i = 0; i < seqLength; i++) {
          for (let j = 0; j < response.data.length; j++) {
            if (this.sequenceList[i].id === response.data[j].id) {
              this.sequenceList.splice(i, 1);
            }
          }
        }
      }
    }, error => {
      this.spinner = false;
    });
  }

  /**
   * callback function when select image
   */
  onSelectFile(event) {
    if (this.subsectionId.length === 1) {
      this.imageUploadUrl = `${window.location.origin}/apigateway/operation/api/v1/image/subsection?subsectionId=${this.subsectionId[0]}`;
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        this.httpClient.post<any>(this.imageUploadUrl, formData).subscribe(
          (res) => {
            // this.imageSrc = res.data.path;
            // this.documentId = res.data.id;
            this.uploadedImages = [...this.uploadedImages, ...res.data];
          },
        );
      }
    } else {
      this.toastr.error('Image Can be upload only for single subsection and part', 'Error');
    }
  }

  /**
   * get image upload id
   */
  getUploadedImage(subsectionId, partId, isNew) {
    this.subsectionService.getImages(subsectionId).subscribe((response: any) => {
      if (isNew) {
        this.uploadedImages = [...this.uploadedImages, ...response.data];
      } else {
        const uploadedLength = JSON.parse(JSON.stringify(this.uploadedImages.length));
        for (let i = 0; i < uploadedLength; i++) {
          for (let j = 0; j < response.data.length; j++) {
            if (this.uploadedImages[i].id === response.data[j].id) {
              this.uploadedImages.splice(i, 1);
            }
          }
        }
        // const clonedImageUpload = this.uploadedImages;
        // for (const list of clonedImageUpload) {
        //   if (list.partsId === partId) {
        //     this.uploadedImages.splice(this.uploadedImages.indexOf(list.partsId), 1);
        //   }
        // }
      }
      this.spinner = false;
    }, error => {
      this.spinner = false;
    });
  }

  /**
   * copy image
   * @param opIds sequence id of selected part
   */
  copyOPSequence(opIds) {
    this.subsectionService.copySequenceList(this.copyStyleId, opIds, this.stageId).subscribe((response: any) => {
      this.getCopiedSequence();
      // this.getSequence(this.copyStyleId);
      this.spinner = false;
      this.toastr.success(response.message, 'Success');
    },
      (error) => {
        this.spinner = false;
        this.toastr.error(error.error.message, 'Error');
      });
  }

  /**
   * get sequence for copied style
   */
  getCopiedSequence() {
    this.copiedSequenceList = [];
    this.copiedImages = [];
    this.subsectionService.getSequence(this.copyStyleId, this.copyStageId).subscribe((response: any) => {
      this.copiedSequenceList = [...this.copiedSequenceList, ...response.data];
    });
  }

}
