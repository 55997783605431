import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { flipInY, bounce, bounceIn, rollIn } from 'ng-animate';
import { OrderService } from './order.service';
import { orderTableCol, ColorTable } from './order-list';
import { ToastrService } from 'ngx-toastr';
// import { ColorPickerModule } from 'ngx-color-picker';
import * as cloneDeep from 'lodash/cloneDeep';
import { Item } from '@syncfusion/ej2-splitbuttons';


@Component({
  selector: 'app-order-creation',
  templateUrl: './order-creation.component.html',
  animations: [
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))])
  ],
  styleUrls: ['./order-creation.component.sass']
})
export class OrderCreationComponent implements OnInit {
  flip: any;
  bounce: any;
  bounceIn: any;
  rollIn: any;
  spinner: boolean;
  color = '#2889e9';
  categoryName: string;
  displayMode: number;
  ordersList: Array<any>;
  searchOrder: string;
  cols: any;
  sortOption: string;
  orderFormData: any = {};
  createSizeData: any = {};
  factoryId: number;
  styleOptions: Array<any>;
  availableStyleList: Array<any>;
  styleSizes: Array<any>;
  totalColorRow: ColorTable[] = [];
  testData: Array<any> = [];
  tableHeader: Array<any> = [];
  orderFormType: string;
  testStyle: any;
  factoryList: any;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public orderService: OrderService,
    public toastr: ToastrService) {
    this.displayMode = 1;
    this.spinner = false;
    this.cols = orderTableCol;
    this.sortOption = 'null';

  }

  ngOnInit() {
    this.getOrderList();
    this.getFactoryId();
  }

  /**
   * check for Card or list view
   * @param mode display mode
   */
  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }

  /**
   * fetch factory id of logged in user
   */
  getFactoryId() {
    // this.spinner = true;
    this.orderService.getFactoryId().subscribe((response: any) => {
      this.factoryList = response.data;
      this.factoryId = response.data[0].id;
      // this.spinner = false;
    });
  }

  /**
   * fetch all orders List
   */
  getOrderList() {
    this.spinner = true;
    this.orderService.getOrdersList().subscribe((response: any) => {
      this.ordersList = response.data;
      for (const order of this.ordersList) {
        order.styleName = order.style.name;
      }
      this.spinner = false;
    });
  }

  /**
   * delete selected order
   * @param id selected order id
   */
  deleteOrder(orderId, index) {
    this.spinner = true;
    this.orderService.deleteOrder(orderId).subscribe((response: any) => {
      this.spinner = false;
      this.toastr.success(response.message, 'Success');
      this.ordersList.splice(index, 1);
    }, error => {
      this.spinner = false;
      this.toastr.error(error.error.message, 'Error');
    });
  }

  /**
   * while searching for available styles
   * @param event string to be searched
   */
  searchStyle(event) {
    this.orderService.searchStyles(event.query, this.factoryId).subscribe((response: any) => {
      const companyList = [];
      for (const comp of response.data) {
        comp.name = comp.name;
        companyList.push(comp.name);
      }
      this.styleOptions = companyList;
      this.availableStyleList = response.data;
    });
  }

  /**
   * capture the id of selected style
   * @param event selected style from options
   */
  captureStyleId(event: any) {
    for (const list of this.availableStyleList) {
      if (list.name === event) {
        this.orderFormData.styleId = list.id;
        this.createSizeData.sizeGridId = list.sizeGridId;
      }
    }
    this.getStyleSize();
  }

  /**
   * get sizes related to selected style
   */
  getStyleSize() {
    this.orderService.getstyleSize(this.orderFormData.styleId).subscribe((response: any) => {
      this.styleSizes = response.data;
      if (this.orderFormType !== 'Update') {
        this.testData.push({ colorCode: '#000000', colorName: 'Black' });
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.testData.length; i++) {
          this.testData[i].sizeQuantity = this.addQuantityCol(response.data);
        }
        this.tableHeader = this.testData[0].sizeQuantity;
      } else {
        this.diffArray(this.styleSizes, this.testData);
      }
    });
  }

  diffArray(styleSizes, testData) {
    for (let i = 0; i < testData.length; i++) {
      const styleSizeIds = styleSizes.map(item => item.id);
      const sizeQuantityIds = testData[i].sizeQuantity.map(item => item.id);
      const diff = styleSizeIds.map((id, index) => {
        if (sizeQuantityIds.indexOf(id) < 0) {
          return styleSizes[index];
        }
      }).concat(sizeQuantityIds.map((id, index) => {
        if (styleSizeIds.indexOf(id) < 0) {
          return testData[i].sizeQuantity[index];
        }
      })).filter(item => item !== undefined);
      const diffArray = this.addQuantityCol(diff);
      diffArray.forEach(element => {
        this.testData[i].sizeQuantity.push(element);
      });
    }
  }

  addQuantityCol(data) {
    data.forEach(element => {
      element['quantity'] = 0;
      // element['orderSizeId'] = null;
    });
    return cloneDeep(data);
  }

  /**
   * creating new row of color table
   */
  add() {
    this.testData.push({ sizeQuantity: this.addQuantityCol(this.styleSizes), colorCode: '#000', colorName: 'Black' });
  }

  /**
   * validate colorname
   */
  validateColorName() {
    for (let i = 0; i <= this.testData.length; i++) {
      for (let j = i; j < this.testData.length; j++) {
        if (i !== j && this.testData[i].colorCode === this.testData[j].colorCode) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * create new Order
   */
  onCreateOrder() {
    this.spinner = true;
    let validate = false;
    for (const sizeData of this.testData) {
      for (const sizes of sizeData.sizeQuantity) {
        if (sizes.quantity === null) {
          validate = true;
          this.toastr.error('Please enter Quantity', 'Error');
          this.spinner = false;
          break;
        }
      }
    }
    const sameColor = this.validateColorName();
    this.orderFormData.orderSizeForms = this.testData;
    if (this.orderFormType === 'Update' && !validate && !sameColor) {
      this.orderService.updateOrder(this.orderFormData, this.orderFormData.orderId).subscribe(
        result => {
          this.ngxSmartModalService.getModal('myModal').close();
          this.orderFormData = {};
          this.testData = [];
          this.spinner = false;
          this.toastr.success(result.message, 'Success');
          this.getOrderList();
        },
        error => {
          this.ngxSmartModalService.getModal('myModal').close();
          this.orderFormData = {};
          this.testData = [];
          this.spinner = false;
          this.toastr.error(error.error.message, 'Error');
        }
      );
    } else if (!validate && !sameColor) {
      this.orderService.createOrder(this.orderFormData, this.orderFormData.styleId).subscribe(
        result => {
          this.ngxSmartModalService.getModal('myModal').close();
          this.orderFormData = {};
          this.testData = [];
          this.spinner = false;
          this.toastr.success(result.message, 'Success');
          this.getOrderList();
        },
        error => {
          this.ngxSmartModalService.getModal('myModal').close();
          this.orderFormData = {};
          this.testData = [];
          this.spinner = false;
          this.toastr.error(error.error.message, 'Error');
        }
      );
    } else if (sameColor) {
      this.spinner = false;
      this.toastr.error('Order Color should be separate', 'Error');
    }

  }

  /**
   * create size for selected style
   */
  createSizeGrid() {
    this.spinner = true;
    this.orderService.createSize(this.createSizeData).subscribe(
      result => {
        this.spinner = false;
        this.ngxSmartModalService.getModal('sizeGridModal').close();
        this.toastr.success(result.message, 'Success');
        this.createSizeData.sizeValue = '';
        this.getStyleSize();
      },
      error => {
        this.spinner = false;
        this.ngxSmartModalService.getModal('sizeGridModal').close();
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  /**
   * binding data while editting order and open popup
   * @param listData data of seleted order while editting
   */
  openNewOrderModal(listData?) {
    if (listData) {
      this.orderFormType = 'Update';
      this.orderFormData.bundleQuantity = listData.bundleQuantity;
      this.createSizeData.sizeGridId = listData.style.sizeGridId;
      this.orderFormData.styleName = listData.style.name;
      this.orderFormData.styleId = listData.style.id;
      this.orderFormData.orderId = listData.id;
      this.orderFormData.orderQuantity = listData.orderQuantity;
      this.orderFormData.orderNumber = listData.orderNumber;
      this.orderFormData.destination = listData.destination;
      this.orderFormData.orderDate = listData.orderDate;
      this.orderFormData.fileHandOverDate = listData.fileHandOverDate;
      this.orderFormData.deliveryDate = listData.deliveryDate;
      this.orderFormData.shipmentDate = listData.shipmentDate;
      this.orderFormData.exFactoryDate = listData.exFactoryDate;
      this.orderFormData.poNumber = listData.poNumber;
      this.orderFormData.active = listData.active;
      this.orderFormData.completed = listData.completed;
      this.testData = listData.sizeOrder;
      this.testStyle = listData.sizeOrder[0].sizeQuantity;
      this.styleSizes = listData.sizeOrder[0].sizeQuantity;
      this.styleSizes = this.styleSizes.map(item => {
        return {
          id: item.id,
          sizeValue: item.sizeValue
        };
      });
      this.getStyleSize();
    } else {
      this.orderFormType = 'Create';
      this.orderFormData = {};
      this.orderFormData.active = true;
      this.testData = [];
    }
    this.ngxSmartModalService.getModal('myModal').open();
  }

  /**
   * removeRow
   */
  removeRow(i) {
    this.testData.splice(i, 1);
  }

  /**
   * open create size modal
   */
  openCreateSizeGridModal() {
    this.ngxSmartModalService.getModal('sizeGridModal').open();
  }

}
