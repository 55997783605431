import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
     title = 'Pro-SMV';
     constructor(public translate: TranslateService) {
     this.translate.addLangs(['en', 'bn','vi','id','es']);
    
    const savedLang = localStorage.getItem('selectedLang');
    const browserLang = this.translate.getBrowserLang();
    const defaultLang = savedLang || browserLang;
    this.translate.setDefaultLang(defaultLang);
    this.translate.use(defaultLang);
    if (!savedLang) {
      localStorage.setItem('selectedLang', defaultLang);
    }
  }

}
