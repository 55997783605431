import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpclientService } from '../shared/httpclient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordData: any = {};
  result: any = {};
  spinner = false;

  constructor(
    private httpClientService: HttpclientService,
    private toaster: ToastrService,
    private route: Router,
    public router: ActivatedRoute) { }

  ngOnInit() {
    const token = this.router.params['_value'].token;
    this.resetPasswordData.resetPasswordToken = token;
  }

  reset() {
    if (this.resetPasswordData.newPassword === this.resetPasswordData.confirmPassword) {
      this.spinner = true;
      this.resetUserPassword(this.resetPasswordData).subscribe((response: any) => {
        this.spinner = false;
        this.toaster.success(response.message, 'Success');
        this.route.navigate(['']);
      }, error => {
        this.spinner = false;
        this.toaster.error(error.error.message, 'Unauthorised');
      });
    } else {
      this.toaster.error("Password does't Match", 'Error');
    }
  }

  resetUserPassword(resetPasswordData): Observable<any[]> {
    const url = `${window.location.origin}/apigateway/login/api/v1/user/resetpassword`;
    return this.httpClientService.put(url, resetPasswordData);
  }
}
