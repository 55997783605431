import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable({
    providedIn: 'root'
})
export class CopyOperationService {

    constructor(
        private http: HttpClient,
        private httpClientService: HttpclientService,
        private httpService: HttpClient
    ) { }

    /**
     * get Factory Id through API call
     */
    getFactoryId() {
        const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
        return this.httpClientService.get(url);
    }

    /**
     * getting parts list of selected Style
     * @param styleId selecte styl id
     */
    getCopysSequence(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/partlist/operationsequences/style?styleId=
        ${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * fetch stages
     */
    getStages() {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/stages`;
        return this.httpClientService.get(url);
      }

    /**
     * search available style list for user
     * @param styleName or query to be search
     * @param factoryId against selected factory
     */
    searchStyles(styleName: any, factoryId: any) {
        const listOfStyle = this.httpService
            .get(`/apigateway/operation/api/v1/autocomplete/style?styleName=${styleName}&factoryId=${factoryId}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
                })
            );
        return listOfStyle;
    }

    /**
     * get sequence of selected styleId
     * @param styleId selected style
     */
    getSequence(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operations/style?styleId=
        ${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * fetch sequence List of selected Parts
     * @param styleId selected style Id
     * @param partId selecte part Id
     */
    getSequenceList(styleId, partId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operation/sequences/style/part?styleId=
                    ${styleId}&partsId=${partId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * copy operation sequence list to another style
     */
    copySequenceList(tostyleId, selectedList, toStageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/copy/operation/sequences/part?toStyleId=
        ${tostyleId}&stageId=${toStageId}`;
        return this.httpClientService.post(url, selectedList);
    }
}
