import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of, fromEvent } from 'rxjs';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { SharedService } from '../shared/shared.service';
import { HttpclientService } from '../shared/httpclient.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HeaderService } from './header.service';
import { CommonService } from '../shared/common.service';
import { Options } from 'ng5-slider';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
  providers: [HeaderService, CommonService]
})


export class HeaderComponent implements OnInit {

  username: string;
  userType: string;
  authenticationToken: string;
  userSwitched: any;
  changePassword = false;
  changePasswordData: any = {};
  spinner = false;
  factoryIdDTO: Array<any> = [];
  factoryId: number;
  freequencyType: any;
  page: string;
  value = 3;
  isIn = false;
  isStyle = false;
  isOperation = false;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 3, legend: '3' },
      { value: 6, legend: '6' },
      { value: 9, legend: '9' },
      { value: 12, legend: '12' },
      { value: -1, legend: 'All' }
    ]
  };
  fileAuthData: any = {};
  styleAuthData: any = {};
  operationAuthData: any = {};
  proAuthData: any = {};
  compRole: any;
  permissionRole: any;
  userRole: any;
  factoryRole: any;
  custRole: any;
  styleRole: any;
  threadRole: any;
  orderRole: any;
  sequenceRole: any;
  opRole: any;
  machineRole: any;
  proRole: any;
  isSuperAdmin: any;
  languageList = [
    { code: 'en', name: 'English' },
    { code: 'bn', name: 'Bangla' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'id', name: 'Bahasa' },
    { code: 'es', name: 'Spanish' }
  ];
  selectedLang:string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    public headerService: HeaderService,
    private httpClientService: HttpclientService,
    private ngxSmartModalService: NgxSmartModalService,
    public sharedService: SharedService,
    public activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    public translate:TranslateService
  ) { }

  ngOnInit() {
     this.selectedLang = localStorage.getItem('selectedLang') || this.translate.getDefaultLang();
    this.page = this.activatedRoute.data['_value'].page;
    this.freequencyType = 6;
    this.getUserDetails();
    this.getUserSwitched();
    this.redirect();
    this.getRolesPermission();
    if (this.page === 'Dashboard') {
      this.getFactory();
    }
    // if (this.route.firstChild.snapshot) {
    //   this.currentUrl = this.url;
    //   this.urlForNavigation = this.url;
    // }
    // this.allAuthoritiesData.currentUrl = this.currentUrl;
  }
onLanguageChange(lang: string): void {

    this.selectedLang = lang;
    this.translate.use(lang);
    localStorage.setItem('selectedLang', lang);
  }
  toggleState() {
    const bool = this.isIn;
    this.isIn = bool === false ? true : false;
  }

  /**
   * fetch factories
   */
  getFactory() {
    this.headerService.getFactory().subscribe((response: any) => {
      if (response.data) {
        this.factoryIdDTO = response.data;
        this.factoryId = response.data[0].id;
        this.updateDashboard();
      }
    });
  }

  /**
   * update dashboard data
   */
  updateDashboard() {
    const data = {
      factoryId: +this.factoryId,
      type: this.value === 15 ? 'All' : this.value,
    };
    this.sharedService.updateDashboard(data);
  }

  /**
   * fetch user details
   */
  getUserDetails() {
    this.username = localStorage.getItem('username');
    this.userType = localStorage.getItem('userType');
    this.authenticationToken = localStorage.getItem('authenticationToken');
    this.isSuperAdmin = localStorage.getItem('isSuperAdmin');
  }

  /**
   * get swticed userData
   */
  getUserSwitched() {
    this.userSwitched = localStorage.getItem('lastUser') ?
      JSON.parse(localStorage.getItem('lastUser')) : {};
  }

  logout() {
    this.logOutUser().subscribe((response) => {
      // window.sessionStorage.clear();
      window.localStorage.clear();
      this.router.navigate(['']);
      this.toastr.success(response.message, 'Success');
    }
    );
  }

  redirect() {
    if (localStorage.getItem('authenticationToken') == null) {
      this.router.navigate(['']);
    }
  }

  logOutUser() {
    const url = `${window.location.origin}/apigateway/login/api/v1/user/logout`;
    return this.getData(url);
  }

  getData(url?): Observable<any> {
    return this.httpClientService.getLogout(url);
  }

  /**
   * switchBack
   */
  switchBack() {
    this.switchUserBack(this.userSwitched['userName']).subscribe((response: any) => {
      if (response.data.authenticationToken != null) {
        localStorage.setItem('authenticationToken', response.data.authenticationToken);
        localStorage.setItem('userType', response.data.user.userType);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('isSuperAdmin', response.data.user.isSuperAdmin ?
          response.data.user.isSuperAdmin : false);
        this.router.navigate(['dashboard']);
        this.toastr.success(response.message, 'Success');
        localStorage.removeItem('lastUser');
        this.userSwitched = {};
        this.getUserDetails();
        this.getRolesPermission();
        this.getFactory();
      }
    });
  }

  switchUserBack(name) {
    const url = `${window.location.origin}/apigateway/login/api/v1/user/switchUser?username=${name}`;
    return this.httpClientService.put(url, {});
  }

  openCreateCategoryModal(): void {
    this.ngxSmartModalService.getModal('changePasswordPopup').open();
  }

  updatePassword() {
    if (this.changePasswordData.newPassword === this.changePasswordData.confirmPassword) {
      this.spinner = true;
      this.resetUserPassword(this.changePasswordData).subscribe((response: any) => {
        this.spinner = false;
        window.localStorage.clear();
        this.router.navigate(['']);
        this.toastr.success(response.message, 'Success');
      }, error => {
        this.spinner = false;
        this.ngxSmartModalService.getModal('changePasswordPopup').close();
        this.toastr.error(error.error.message, 'Unauthorised');
      });
    } else {
      this.toastr.error("Password does't Match", 'Error');
    }
  }

  resetUserPassword(resetPasswordData): Observable<any[]> {
    const url = `${window.location.origin}/apigateway/login/api/v1/user/changepassword`;
    return this.httpClientService.put(url, resetPasswordData);
  }

  getRolesPermission() {
    this.commonService.getRoleAndPermission().subscribe((response: any) => {
      this.sharedService.updateAuthData(response.data.modules);
      this.fileAuthData = response.data.modules.find((el) => {
        return el.name === 'File & Setup';
      });
      this.compRole = this.fileAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Company') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      this.factoryRole = this.fileAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Factory') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      this.userRole = this.fileAuthData.subModules.find((el) => {
        if (el.subModuleName === 'User') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      this.permissionRole = this.fileAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Role & Permission') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      // this.compRole = this.fileAuthData.subModules ? this.fileAuthData.subModules[3].permission.canAccess : false;
      // this.factoryRole = this.fileAuthData.subModules ? this.fileAuthData.subModules[0].permission.canAccess : false;
      // this.userRole = this.fileAuthData.subModules ? this.fileAuthData.subModules[1].permission.canAccess : false;
      // this.permissionRole = this.fileAuthData.subModules ? this.fileAuthData.subModules[2].permission.canAccess : false;
      this.styleAuthData = response.data.modules.find((el) => {
        return el.name === 'Style & Order';
      });
      this.custRole = this.styleAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Customer and Supplier') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      this.styleRole = this.styleAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Style') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      this.threadRole = this.styleAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Thread Consumption') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      this.orderRole = this.styleAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Order') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      // this.custRole = this.styleAuthData.subModules ? this.styleAuthData.subModules[0].permission.canAccess : false;
      // this.styleRole = this.styleAuthData.subModules ? this.styleAuthData.subModules[6].permission.canAccess : false;
      // this.threadRole = this.styleAuthData.subModules ? this.styleAuthData.subModules[5].permission.canAccess : false;
      // this.orderRole = this.styleAuthData.subModules ? this.styleAuthData.subModules[1].permission.canAccess : false;
      this.operationAuthData = response.data.modules.find((el) => {
        return el.name === 'Operation';
      });
      this.machineRole = this.operationAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Operation') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      this.opRole = this.operationAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Operation Sequence') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      this.sequenceRole = this.operationAuthData.subModules.find((el) => {
        if (el.subModuleName === 'Machine') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      // this.machineRole = this.operationAuthData.subModules ? this.operationAuthData.subModules[2].permission.canAccess : false;
      // this.opRole = this.operationAuthData.subModules ? this.operationAuthData.subModules[0].permission.canAccess : false;
      // this.sequenceRole = this.operationAuthData.subModules ? this.operationAuthData.subModules[1].permission.canAccess : false;
      this.proAuthData = response.data.modules.find((el) => {
        return el.name === 'ProSmv';
      });
      this.proRole = this.proAuthData.subModules.find((el) => {
        if (el.subModuleName === 'ProSmv') {
          return el.permission.canAccess;
        } else {
          return false;
        }
      });
      // this.proRole = this.proAuthData.subModules ? this.proAuthData.subModules[0].permission.canAccess : false;
    });
  }
}
