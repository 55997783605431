import { Injectable } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from './shared.service';
import { RequestOptions } from '@angular/http';

@Injectable({
  providedIn: 'root'
})

export class HttpclientService {
  delete(arg0: string, options: { headers: HttpHeaders; body: { name: string; id: string; }; }): any {
    throw new Error("Method not implemented.");
  }

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    public sharedService: SharedService
  ) { }

  createAuthorizationHeader(headers: HttpHeaders) {
    headers.set('Authorization', 'Basic ' +
      '23456778');
    return headers;
  }

  get(url) {
    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeader(headers);
    return this.http.get(url, {
      headers: headers
    });
  }

  postLogin(url, data): Observable<any> {
    return this.http.post(url, data);
  }

  getLogout(url): Observable<any> {
    return this.http.get(url);
  }

  getOTP(url): Observable<any> {
    return this.http.get(url);
  }

  post(url, data): Observable<any> {
    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeader(headers);
    return this.http.post(url, data, {
      headers: headers
    });
  }

  put(url, data): Observable<any> {
    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeader(headers);
    return this.http.put(url, data, {
      headers: headers
    })
  }
  getFile(url) {
    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeader(headers);
    return this.http.get(url, {
      headers: headers,
      responseType: 'blob' as 'json'
    });
  }

  remove(url, data): Observable<any> {
    const dataToSend = {
      headers: new HttpHeaders({
      }),
      body: data
    };
    return this.http.delete(url, dataToSend);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const mobileView = localStorage.getItem('mobileView') ? JSON.parse(localStorage.getItem('mobileView')) : false;
    const mobileToken = localStorage.getItem('mobileToken') ? localStorage.getItem('mobileToken') : '';
    const authToken = mobileView ? mobileToken : localStorage.getItem('authenticationToken');
    if (authToken != null) {
      req = req.clone({
        setHeaders: {
          Accept: 'application/json',
          Authorization: authToken
        }
      });
    }
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.sharedService.updateFeedbackModal(true);
          window.localStorage.clear();
          this.router.navigate(['']);
          this.toastr.error('User Logged in from another device', 'Access Denied');
        } else {
          return throwError(err);
        }
      })
    );
  }
}

