import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {

  constructor(private http: HttpClient, private httpClientService: HttpclientService, private httpService: HttpClient) { }

  getCustomer() {
    if (localStorage.getItem('userType') === 'ROLE_USER') {
        const url = `${window.location.origin}/apigateway/style/api/v1/customers/user`;
        return this.httpClientService.get(url);
      }
    if (localStorage.getItem('userType') ==='ROLE_COMPANYADMIN') {
        const url = `${window.location.origin}/apigateway/style/api/v1/customers/company`;
        return this.httpClientService.get(url);
      }
  }

  createCustomer(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/register/customer`;
    return this.httpClientService.post(url, data);
  }

  updateCustomer(data, customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/update/customer?customerId=` + customerId;
    return this.httpClientService.put(url, data);
  }

  createBrand(data, customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/add/brand?customerId=` + customerId;
    return this.httpClientService.post(url, data);
  }

  updateBrand(data, brandId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/brand/${brandId}`;
    return this.httpClientService.put(url, data);
  }

  getBrands(customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/get/allBrands?customerId=` + customerId;
    return this.httpClientService.get(url);
  }

  getFactoryId() {
    const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
    return this.httpClientService.get(url);
  }

  companySearch(companyName: string) {
    const url = `${window.location.origin}/apigateway/style/api/v1/auto/complete/company?companyName=`;
    return this.httpClientService.get(url);
  }

  statusUser(userId: number, status: boolean) {
    const url = `${window.location.origin}/apigateway/style/api/v1/set/user/status?userId=${userId}&active=${status}`;
    return this.httpClientService.get(url);
  }

  search(companyName) {
    var listOfCompany = this.httpService.get('/apigateway/style/api/v1/auto/complete/company?companyName=' + companyName)
    .pipe(
        debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map(
            (data: any) => {
                return (
                    data.length != 0 ? data as any[] : [{'Company Name': 'No Company Found'} as any]
                );
            }
    ));
    return listOfCompany;
}

searching(roleName: any, companyId: any) {
  var listOfrole = this.httpService.get(`/apigateway/style/api/v1/auto/complete/company/role?roleName=${roleName}&companyId=${companyId}`)
  .pipe(
      debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
      map(
          (data: any) => {
              return (
                  data.length != 0 ? data as any[] : [{'Role Name': 'No Role Found'} as any]
              );
          }
  ));
  return listOfrole;
}

searchFactory(factoryName) {
  var listOfFactory = this.httpService.get('/apigateway/style/api/v1/auto/complete/factory?factoryName=' + factoryName)
  .pipe(
      debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
      map(
          (data: any) => {
              return (
                  data.length != 0 ? data as any[] : [{'factory Name': 'No factory Found'} as any]
              );
          }
  ));
  return listOfFactory;
}

  deleteConsumer(customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/delete/customer?customerId=` + customerId;
    return this.httpClientService.remove(url, customerId);
  }

  deleteBrand(brandId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/delete/brand?branchId=${brandId}`;
    return this.httpClientService.remove(url, {});
  }
}
