import { Component, OnInit, OnDestroy } from '@angular/core';
import { SlideInOutAnimation } from '../animations/animation';
import { ThreadConsumptionService } from './thread-consumption.service';
import { threadConsumptionColumns, machineThreadCols, OrderthreadConsumptionColumns } from './thread-consumption.list';
import { Router } from '@angular/router';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-thread-consumption',
  templateUrl: './thread-consumption.component.html',
  styleUrls: ['./thread-consumption.component.sass'],
  providers: [ThreadConsumptionService],
  animations: [
    SlideInOutAnimation
  ]
})
export class ThreadConsumptionComponent implements OnInit, OnDestroy {
  searchText: any;
  pageToRedirect: string;
  animationState = 'out';
  styleName: string;
  styleId: number;
  styleOptions: Array<any>;
  machineOptions: Array<any>;
  factoryId: number;
  spinner: boolean = true;
  availableStyleList: Array<any>;
  availableMachineList: Array<any>;
  cols: Array<any>;
  orderwiseTCCols: Array<any>;
  operationwiseTC: Array<any>;
  orderwiseTC: Array<any>;
  machineThreadCols: Array<any>;
  machineThreadData: Array<any> = [];
  totalThreadConsumption: number;
  stageList: Array<any> = [];
  stageId: number;
  factoryList: Array<any> = [];
  threadAuthData: any = {};

  constructor(
    public threadService: ThreadConsumptionService,
    public sharedService: SharedService,
    public router: Router) {
    this.cols = threadConsumptionColumns;
    this.orderwiseTCCols = OrderthreadConsumptionColumns;
    this.machineThreadCols = machineThreadCols;
  }

  ngOnInit() {
    this.getFactoryId();
    this.getStages();
    this.getAuthData();
    this.pageToRedirect = localStorage.getItem('lastPage');
    const style = JSON.parse(localStorage.getItem('selectedStyle'));
    if (style) {
      this.styleName = style.name;
      this.styleId = style.id;
      setTimeout(() => {
        this.getThreadConsumption();
        this.getMachineThreadConsumption();
      }, 1000);
    } else {
      this.spinner = false;
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('lastPage');
  }

  /**
   * fetch role and permissions
   */
  getAuthData() {
    // this.spinner = true;
    const auth = this.sharedService.authData$.subscribe((data: any) => {
      const authData = data.find((el) => {
        return el.name === 'Style & Order';
      });
      const threadAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'Thread Consumption';
      });
      this.threadAuthData = threadAuthData;
      // this.spinner = false;
    });
  }

  /**
   * 
   */
  redirectToLastPage() {
    this.router.navigate([this.pageToRedirect]);
  }

  /**
   * show or translate add operation section
   */
  toggleShowDiv(divName: string) {
    if (divName === 'divA') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }

  /**
   * fetch factory id of logged in user
   */
  getFactoryId() {
    // this.spinner = true;
    this.threadService.getFactoryId().subscribe((response: any) => {
      this.factoryList = response.data;
      this.factoryId = response.data[0].id;
      // this.spinner = false;
    });
  }

  /**
   * get Stages from Server
   */
  getStages() {
    // this.spinner = true;
    this.threadService.getStages().subscribe((response: any) => {
      this.stageList = response.data;
      this.stageId = 5;
      // this.spinner = false;
    });
  }

  /**
   * while searching for available styles
   * @param event string to be searched
   */
  searchStyle(event) {
    this.threadService.searchStyles(event.query, this.factoryId).subscribe((response: any) => {
      const companyList = [];
      for (const comp of response.data) {
        comp.name = comp.name;
        companyList.push(comp.name);
      }
      this.styleOptions = companyList;
      this.availableStyleList = response.data;
    });
  }

  /**
   * capture the id of selected style
   * @param event selected style from options
   */
  captureStyleId(event: any) {
    for (const list of this.availableStyleList) {
      if (list.name === event) {
        this.styleId = list.id;
      }
    }
    this.getThreadConsumption();
  }
 ThreadPrint() {
    this.spinner = true;
    this.threadService.getThreadPrint(this.styleId, this.stageId).subscribe((response: any) => {
    const fileNameTemp = response.data != null && response.data.indexOf('-') > -1 ?
      response.data.substring(response.data.indexOf('-') + 1) : response.data + '.pdf';
      this.downloadFile(response.data, fileNameTemp);
    }, (error) => {
      this.spinner = false;
    });
  }

  downloadFile(fileName: string, fileDownloadName) {
    this.threadService.downloadFile(fileName).subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const downloadURL = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = fileDownloadName;
      link.click();
      this.spinner = false;
    }, (error) => {
      this.spinner = false;
    });
  }

  getThreadConsumption() {
    this.spinner = true;
    this.threadService.getThreadsList(this.styleId, this.stageId).subscribe((response: any) => {
      this.spinner = false;
      this.operationwiseTC = response.data.operationwiseTC;
      this.orderwiseTC = response.data.orderwiseTC;
      this.totalThreadConsumption = response.data.totalThreadConsumption;
      this.getMachineThreadConsumption();
    }, error => {
      this.spinner = false;
    });
  }

  /**
   * get machine thread consumption data
   */
  getMachineThreadConsumption() {
    this.spinner = true;
    this.threadService.getMachineThreadsList(this.styleId, this.stageId).subscribe((response: any) => {
      this.spinner = false;
      this.machineThreadData = response.data;
    }, error => {
      this.spinner = false;
    });
  }

  /**
   * 
   */
  focusOutFunction(index) {
    this.spinner = true;
    for (const item of this.machineThreadData) {
      item.styleId = this.styleId;
      item.machineId = item.machineid;
      item.stageId = this.stageId;
    }
    if (this.machineThreadData[index].threadQuality !== '' && !this.machineThreadData[index].machinetqid) {
      this.threadService.saveMachineThread(this.machineThreadData[index]).subscribe((response: any) => {
        this.spinner = false;
        this.getThreadConsumption();
      });
    } else {
      this.machineThreadData[index].machineTQId = this.machineThreadData[index].machinetqid;
      this.threadService.updateMachineThread(this.machineThreadData[index]).subscribe((response: any) => {
        this.spinner = false;
        this.getThreadConsumption();
      });
    }
  }

  /**
   * update Allowance
   */
  updateAllowance(data) {
  }

}
