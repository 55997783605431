import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable({
  providedIn: 'root'
})
export class OperationService {
  constructor(
    private http: HttpClient,
    private httpClientService: HttpclientService,
    private httpService: HttpClient
  ) { }

  getMachine() {
    const url = `${window.location.origin}/apigateway/style/api/v1/machines/user`;
    return this.httpClientService.get(url);
  }

  getOperations(factoryId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/${factoryId}/operation`;
    return this.httpClientService.get(url);
  }

  searchOperations(factoryId,searchValue,searchType) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/${factoryId}/search/operation?searchType=${searchType}&searchValue=${searchValue}`;
    return this.httpClientService.get(url);
  }

  search(machineName) {
    var listOfMachine = this.httpService
      .get('/apigateway/style/api/v1/auto/complete/machine?machineName=' + machineName)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length != 0 ? (data as any[]) : [{ 'Machine Name': 'No Machine Found' } as any];
        })
      );
    return listOfMachine;
  }

  createMachine(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/machine`;
    return this.httpClientService.post(url, data);
  }

  createOperations(data, factoryId, styleId, cartType, stageId) {
    let url;
    if (cartType) {
      url = `${window.location.origin}/apigateway/operation/api/v1/create/operation?factoryId=
      ${factoryId}&styleId=${styleId}&stageId=${stageId}&addToStyle=true`;
    } else {
      url = `${window.location.origin}/apigateway/operation/api/v1/create/operation?factoryId=
      ${factoryId}&styleId=${styleId}&stageId=${stageId}&addToStyle=false`;
    }
    return this.httpClientService.post(url, data);
  }

  createStitch(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/stitchClass`;
    return this.httpClientService.post(url, data);
  }

  updateMachine(data, machineId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/update/machine?machineId=` + machineId;
    return this.httpClientService.put(url, data);
  }

  updateOperation(data, factoryId, operationId) {
    const url = `${
      window.location.origin
      }/apigateway/operation/api/v1/update/operation?factoryId=${factoryId}&operationId=${operationId}`;
    return this.httpClientService.put(url, data);
  }

  copyOperation(operationId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/copy/operation?operationId=${operationId}`;
    return this.httpClientService.put(url, operationId);
  }

  uploadOperationDocs(docsData) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/operation/documents`;
    return this.httpClientService.post(url, docsData);
  }

  createBrand(data, customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/add/brand?customerId=` + customerId;
    return this.httpClientService.post(url, data);
  }

  getBrands(customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/get/allBrands?customerId=` + customerId;
    return this.httpClientService.get(url);
  }

  getFactoryId() {
    const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
    return this.httpClientService.get(url);
  }

  getStages() {
    const url = `${window.location.origin}/apigateway/operation/api/v1/get/stages`;
    return this.httpClientService.get(url);
  }

  getStitchClass() {
    const url = `${window.location.origin}/apigateway/style/api/v1/stitchClasses/user`;
    return this.httpClientService.get(url);
  }

  companySearch(companyName: string) {
    const url = `${window.location.origin}/apigateway/style/api/v1/auto/complete/company?companyName=`;
    return this.httpClientService.get(url);
  }

  statusUser(userId: number, status: boolean) {
    const url = `${window.location.origin}/apigateway/style/api/v1/set/user/status?userId=${userId}&active=${status}`;
    return this.httpClientService.get(url);
  }

  deleteOperation(operationId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/delete/operation?operationId=` + operationId;
    return this.httpClientService.remove(url, operationId);
  }

  searchMaterial(materialName: any, factoryId: any) {
    var listOfMaterial = this.httpService
      .get(`/apigateway/operation/api/v1/autocomplete/material?materialName=${materialName}&factoryId=${factoryId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length != 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
        })
      );
    return listOfMaterial;
  }

  searchGrade(gradeName: any, factoryId: any) {
    var listOfGrade = this.httpService
      .get(`/apigateway/operation/api/v1/autocomplete/grade?gradeName=${gradeName}&factoryId=${factoryId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length != 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
        })
      );
    return listOfGrade;
  }

  /**
   * To Search work aid
   * @param workAidName name to search 
   * @param factoryId selected factory id
   */
  searchWorkAid(workAidName, factoryId) {
    var listOfWorkAid = this.httpService
    .get(`/apigateway/operation/api/v1/${factoryId}/search/workAid?searchValue=${workAidName}`)
    .pipe(
      debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
      map((data: any) => {
        return data.length != 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
      })
    );
  return listOfWorkAid;
  }

  searchProduct(productName: any, factoryId: any) {
    var listOfProduct = this.httpService
      .get(`/apigateway/operation/api/v1/autocomplete/product?productName=${productName}&factoryId=${factoryId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length != 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
        })
      );
    return listOfProduct;
  }

  searchOpTypes(opTypesName: any, factoryId: any) {
    var listOfopTypes = this.httpService
      .get(`/apigateway/operation/api/v1/autocomplete/opTypes?opTypesName=${opTypesName}&factoryId=${factoryId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length != 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
        })
      );
    return listOfopTypes;
  }

  searchParts(partsName: any, factoryId: any) {
    var listOfParts = this.httpService
      .get(`/apigateway/operation/api/v1/autocomplete/parts?partsName=${partsName}&factoryId=${factoryId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length != 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
        })
      );
    return listOfParts;
  }

  searchSkills(skillName: any, factoryId: any) {
    var listOfSkill = this.httpService
      .get(`/apigateway/operation/api/v1/autocomplete/skill?skillName=${skillName}&factoryId=${factoryId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length != 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
        })
      );
    return listOfSkill;
  }

  searchStyles(styleName: any, factoryId: any) {
    const listOfStyle = this.httpService
      .get(`/apigateway/operation/api/v1/autocomplete/style?styleName=${styleName}&factoryId=${factoryId}`)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length != 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
        })
      );
    return listOfStyle;
  }

  /**
   * search data for table
   * @param operationId
   * @param styleId
   */
  searchByDescOP(dataToBeSearch: any, factoryId) {
    let listOfStyle;
    if (isNaN(parseInt(dataToBeSearch))) {
      listOfStyle = this.httpService
        .get(`/apigateway/operation/api/v1/operations/operationNumber/description?factoryId=
      ${factoryId}&description=${dataToBeSearch}`)
        .pipe(
          debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
          map((data: any) => {
            return data.length !== 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
          })
        );
    } else {
      listOfStyle = this.httpService
        .get(`/apigateway/operation/api/v1/operations/operationNumber/description?factoryId=
      ${factoryId}&operationNumber=${parseInt(dataToBeSearch)}`)
        .pipe(
          debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
          map((data: any) => {
            return data.length !== 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
          })
        );
    }
    return listOfStyle;
  }

  addOperationToCart(operationId: any, styleId: any, stageId) {
    // tslint:disable-next-line:max-line-length
    const url = `${
      window.location.origin
      }/apigateway/operation/api/v1/add/operation/to/style?operationId=${operationId}&styleId=${styleId}&stageId=${stageId}`;
    return this.httpClientService.put(url, operationId);
  }

  /**
   * 
   */
  cartOperations(cartList, styleId, stageId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/create/operation/sequences?styleId=
    ${styleId}&stageId=${stageId}&delete=false`;
    return this.httpClientService.post(url, cartList);
  }

  /**
   * create grade
   */
  createGrade(factoryId, data) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/grade?factoryId=${factoryId}`;
    return this.httpClientService.post(url, data);
  }

  /**
   * update grade
   */
  updateGrade(data) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/grade/${data.id}`;
    return this.httpClientService.put(url, data);
  }

  /**
   * delete grade
   */
  deleteGrade(gradeId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/grade/${gradeId}`;
    return this.httpClientService.remove(url, {});
  }
  /**
   * get Grade
   */
  getGrade(factoryId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/grade?factoryId=${factoryId}`;
    return this.httpClientService.get(url);
  }

  /**
   * fetch operation history
   * @param id selected operation
   */
  getOpHistory(opId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/operation${opId}/history`;
    return this.httpClientService.get(url);
  }

  /**
   * delete document
   */
  deleteDoc(opId, docId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/operation/${opId}/document/${docId}`;
    return this.httpClientService.remove(url, {});
  }

  /**
   * To get the list of style and stage
   * @param operationId selected operationId
   */
  getStyleStageList(operationId) {
    const url = `${window.location.origin}/apigateway/operation/api/v1/operation/styleStage/${operationId}`;
    return this.httpClientService.get(url);
  }
}
