import { Component, OnInit } from '@angular/core';
import { FeedBackService } from './feed-back.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-feed-back',
  templateUrl: './feed-back.component.html',
  styleUrls: ['./feed-back.component.sass'],
  providers: [FeedBackService]
})
export class FeedBackComponent implements OnInit {
  feedBackLists: Array<any> = [];
  feedBackImage: string;

  constructor(
    public feedBackService: FeedBackService,
    public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.getFeedbackList();
  }

  /**
   * fetch feedback list
   */
  getFeedbackList() {
    this.feedBackService.getFeedback().subscribe((response: any) => {
      this.feedBackLists = response.data;
    });
  }

  /**
   * open popup to view image
   */
  viewImage(data) {
    // window.open(data.image);
    this.feedBackImage = data.image;
    this.ngxSmartModalService.getModal('imageModal').open();
  }

}
