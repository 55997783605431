import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flipInY, bounce, bounceIn, rollIn } from 'ng-animate';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CompanyService } from './company.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { UserService } from '../users/user.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { Route, Router } from '@angular/router';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  animations: [
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))])
  ],
  styleUrls: ['./company.component.sass']
})
export class CompanyComponent implements OnInit {
  flip: any;
  bounce: any;
  bounceIn: any;
  rollIn: any;
  companyList: any = [];
  createCompany: any = {};
  companyUpdate: any = {};
  NgxSmartModalService: any;
  displayMode = 1;
  isChecked: any;
  statusData: any = {};
  url = '';
  result: any = {};
  createFactory: any = {};
  createUser: any = {};
  optionSelected: string;
  searchText: any;
  searchCompany: FormControl = new FormControl();
  companySearchList: Array<any> = [];
  text: string;
  results: Array<any> = [];
  resultsFactory: Array<any> = [];
  resultsRole: Array<any> = [];
  companyId: any;
  coList: any;
  roleList: any;
  factoryList: any;
  spinner: boolean;
  uploadImgUrl: string;
  imageSrc: any;
  documentId: any;
  userType: string;
  compAuthData: any = {};
  isSuperAdmin: string;
  @ViewChild('fileInput')fileInput: ElementRef;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private service: UserService,
    public httpClient: HttpClient,
    public sharedService: SharedService,
    public router: Router,
  ) {
    this.optionSelected = 'null';
    this.createCompany.name = null;
    this.createCompany.landLineNumber = null;
    this.createCompany.userName = null;
    this.createCompany.password = null;
    this.createCompany.mobileNumber = null;
    this.createCompany.email = null;
    this.createCompany.companyHead = null;
    this.createCompany.gstNumber = null;
    this.createCompany.address = null;

    // this.createFactory.factoryName = null;
    this.createFactory.companyId = this.companyId;
    this.createFactory.licenseExpDate = null;
    this.createFactory.licenseIssueDate = null;
    this.createFactory.membership = null;
    this.createFactory.mobileNumber = null;
    this.createFactory.email = null;
    this.createFactory.address = null;
    this.createFactory.membership = null;
    this.createFactory.status = true;

    // this.optionSelected = 'username';
    this.createUser.companyId = this.companyId;
    this.createUser.name = null;
    this.createUser.username = null;
    this.createUser.password = null;
    this.createUser.confirmPassword = null;
    this.createUser.mobileNumber = null;
    this.createUser.email = null;
    this.createUser.roleId = this.resultsFactory;
    this.createUser.factoryNames = [];
    this.createUser.active = true;
  }
  ngOnInit() {
    this.getCompanyList();
    this.getAuthData();
    this.userType = localStorage.getItem('userType');
    this.isSuperAdmin = localStorage.getItem('isSuperAdmin');
    this.uploadImgUrl = `${window.location.origin}/apigateway/admin/api/v1/upload/companyLogo`;
  }

  /**
   * fetch role and permissions assigned to it
   */
  getAuthData() {
    const auth = this.sharedService.authData$.subscribe((data: any) => {
      const authData = data.find((el) => {
        return el.name === 'File & Setup';
      });
      const compAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'Company';
      });
      this.compAuthData = compAuthData;
    });
  }

  /**
   * switch admin
   */
  switchAdmin(data) {
    // const userName = '';
    this.companyService.switchUser(data.userName).subscribe((response: any) => {
      const switchUserData = {
        userName: localStorage.getItem('username'),
        isSwitched: true
      };
      localStorage.setItem('lastUser', JSON.stringify(switchUserData));
      if (response.data.authenticationToken != null) {
        localStorage.setItem('authenticationToken', response.data.authenticationToken);
        localStorage.setItem('userType', response.data.user.userType);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('isSuperAdmin', response.data.user.isSuperAdmin ?
          response.data.user.isSuperAdmin : false);
        this.spinner = false;
        this.router.navigate(['/dashboard']);
        this.toastr.success(response.data.message, 'Success');
      }
    });
  }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getCompanyList() {
    this.companyService.getCompany().subscribe((response: any) => {
      if (response.data) {
        this.companyList = response.data;
      } else {
      }
    });
  }

  search(event) {
    this.service.search(event.query).subscribe((response: any) => {
      const companyList = [];
      for (const comp of response.data) {
        comp.name = comp.name;
        companyList.push(comp.name);
        // this.companyId = comp.id;
      }
      this.results = companyList;
      this.coList = response.data;
    });
  }

  captureId(event: any) {
    for (var i = 0; i < this.coList.length; i++) {
      if (this.coList[i].name === event) {
        this.companyId = this.coList[i].id;
      }
    }
  }

  searching(event) {
    this.companyId = this.createUser.companyId;
    this.service.searching(event.query, this.companyId).subscribe((response: any) => {
      const roleList = [];
      for (const role of response.data) {
        role.roleName = role.roleName;
        roleList.push(role.roleName);
        // this.companyId = comp.id;
      }
      this.resultsFactory = roleList;
      this.roleList = response.data;
    });
  }

  captureRoleId(event: any) {
    for (var i = 0; i < this.roleList.length; i++) {
      if (this.roleList[i].roleName === event) {
        this.resultsFactory = this.roleList[i].id;
      }
    }
  }

  searchFactory(event) {
    this.companyId = this.createUser.companyId;
    this.service.searchFactory(event.query, this.companyId).subscribe((response: any) => {
      const factoryList = [];
      for (const factory of response.data) {
        factory.name = factory.name;
        factoryList.push(factory.name);
        // this.companyId = comp.id;
      }
      this.resultsRole = factoryList.length > 0 ? factoryList : ['No Data Available'];
      this.factoryList = response.data;
    });
  }

  captureFactoryId(event: any) {
    for (var i = 0; i < this.factoryList.length; i++) {
      if (this.factoryList[i].name === event) {
        this.resultsRole = this.factoryList[i].id;
      }
    }
  }

  checkValue(companyId: number, status: boolean) {
    this.companyService.statusCompany(companyId, status).subscribe((response: any) => {
      this.toastr.success(response.message, 'Success');
    });
  }

  checkValue1(factoryId: number, status: boolean) {
    this.companyService.statusFactory(factoryId, status).subscribe((response: any) => {
      this.toastr.success(response.message, 'Success');
    });
  }

  deleteCompany(list) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        this.companyService.deleteCompany(list.id).subscribe((response: any) => {
          const index: number = this.companyList.indexOf(list);
          this.companyList.splice(index, 1);
          this.toastr.success(response.message, 'Success');
        });
      }
    });
  }


  /**
   * Event Fired before image upload
   */
  beforeImgUpload(): void {
    this.spinner = true;
  }

  /**
   * For uploading/changing image
   * @param event response related data
   */
  imageUpload(event): void {
    const response = JSON.parse(event.xhr.response);
    this.spinner = false;
    // if (response.status === 200) {
    // } else {
    // }
  }

  /**
   * reset create company modal
   */
  resetCreateCompany() {
    this.ngxSmartModalService.getModal('myModal').close();
    this.imageSrc = '';
    this.documentId = null;
  }

  onSelectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.httpClient.post<any>(this.uploadImgUrl, formData).subscribe(
        (res) => {
          this.imageSrc = res.data.path;
          this.documentId = res.data.id;
          event.target.value = '';
        },
        (err) => {
        }
      );
    }
  }

  public openModal(data) {
    this.ngxSmartModalService.getModal('myModal1').open(data);
    this.companyUpdate.name = data.name;
    this.companyUpdate.landLineNumber = data.landLineNumber;
    this.companyUpdate.userName = data.userName;
    this.companyUpdate.password = data.password;
    this.companyUpdate.mobileNumber = data.mobileNumber;
    this.companyUpdate.email = data.email;
    // this.companyUpdate.companyHead = data.companyHead;
    this.companyUpdate.companyHead = data.contactPerson;
    this.companyUpdate.gstNumber = data.gstNumber;
    this.companyUpdate.address = data.address;
    this.companyUpdate.companyId = data.id;
    this.documentId = data.document ? data.document.id : '';
    this.imageSrc = data.document ? data.document.path : '';
    // this.fileInput.nativeElement.value = data.document ? data.document.path : '';
  }

  public openNewCompanyModal() {
    this.imageSrc = '';
    this.documentId = null;
    this.ngxSmartModalService.getModal('myModal').open();
  }

  public openNewUserModal(data) {
    this.ngxSmartModalService.getModal('myModal3').open(data);
    this.createUser.companyName = data.name;
    this.createUser.companyId = data.id;
  }

  public openNewFactoryModal(data) {
    this.imageSrc = '';
    this.documentId = null;
    this.ngxSmartModalService.getModal('myModal2').open(data);
    this.createFactory.companyName = data.name;
    this.createFactory.companyId = data.id;
  }

  onUserCreate(form: NgForm) {
    this.createUser.companyId = this.companyId;
    this.createUser.roleId = this.resultsFactory;
    this.companyService.createUser(this.createUser).subscribe(
      result => {
        form.reset();
        // window.location.reload();
        this.toastr.success(result.message, 'Success');
        this.getCompanyList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  onCompanyCreate(form: NgForm) {
    this.createCompany.documentId = this.documentId;
    this.companyService.createCompany(this.createCompany).subscribe(
      result => {
        form.reset();
        // window.location.reload();
        this.ngxSmartModalService.getModal('myModal').close();
        this.toastr.success(result.message, 'Success');
        this.getCompanyList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  onCompanyUpdate(form: NgForm) {
    this.companyUpdate.documentId = this.documentId;
    this.companyService.updateCompany(this.companyUpdate).subscribe(
      result => {
        form.reset();
        // window.location.reload();
        this.toastr.success(result.message, 'Success');
        this.getCompanyList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  onFactoryCreate(form: NgForm) {
    this.createFactory.documentId = this.documentId;
    this.companyService.createFactory(this.createFactory).subscribe(
      result => {
        form.reset();
        this.ngxSmartModalService.getModal('myModal2').close();
        this.toastr.success(result.message, 'Success');
        this.getCompanyList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  /**
   * update date on changing membership type
   * @param membershipType trial or permanent
   */
  updateDates(membershipType) {
    this.createFactory.licenseIssueDate = new Date();
    this.createFactory.licenseExpDate = new Date();
    if (membershipType === 'trial') {
      // this.createFactory.licenseExpDate = new Date();
      this.createFactory.licenseExpDate.setMonth(new Date().getMonth() + 1);
    } else {
      this.createFactory.licenseExpDate.setMonth(new Date().getMonth() + 12);
    }
  }
}
