export class Message {
    fromId: any;
    toId: any;
    message: string;
    seenOn?: Date;
}

export class User {
    id: any;
    firstName: string;
    status: UserStatus;
    avatar: string;
    userId: string;
}

export class ChatWindow {
    chattingTo: User;
    messages: Array<any> = [];
    roomId: string;
    typing = false;
    minimize = false;
    fromToken = '';
    messageContent: string;
    focus = false;
    staffList: Array<any> = [];
    groupName: string = null;
    groupNameInput: string = null;
    uniqueId: number;
    groupChat = false;
    typingUserNames = '';
    groupMembersList: Array<any> = [];
    popperOpen = false;
    constructor() {

    }
}

export enum UserStatus {
    Online,
    Busy,
    Away,
    Offline
}
