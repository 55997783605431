import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable({
  providedIn: 'root'
})
export class StylesService {
  constructor(
    private http: HttpClient,
    private httpClientService: HttpclientService,
    private httpService: HttpClient
  ) {}

  getStyle(page,size) {
    const url = `${window.location.origin}/apigateway/style/api/v1/styles/user?page=${page}&limit=${size}`;
    return this.httpClientService.get(url);
  }

  createStyle(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/style`;
    return this.httpClientService.post(url, data);
  }

  createStitch(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/stitchClass`;
    return this.httpClientService.post(url, data);
  }

  updateStyle(data, styleId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/update/style?styleId=` + styleId;
    return this.httpClientService.post(url, data);
  }

  copyStyle(data, styleId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/copy/style?styleId=` + styleId;
    return this.httpClientService.post(url, data);
  }

  createBrand(data, customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/add/brand?customerId=` + customerId;
    return this.httpClientService.post(url, data);
  }

  getBrands(customerId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/get/allBrands?customerId=` + customerId;
    return this.httpClientService.get(url);
  }

  getFactoryId() {
    const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
    return this.httpClientService.get(url);
  }

  getStitchClass() {
    const url = `${window.location.origin}/apigateway/style/api/v1/stitchClasses/user`;
    return this.httpClientService.get(url);
  }

  companySearch(companyName: string) {
    const url = `${window.location.origin}/apigateway/style/api/v1/auto/complete/company?companyName=`;
    return this.httpClientService.get(url);
  }

  statusStyle(styleId: number, status: boolean) {
    const url = `${
      window.location.origin
    }/apigateway/style/api/v1/set/style/status?styleId=${styleId}&active=${status}`;
    return this.httpClientService.get(url);
  }

  deleteStyle(styleId) {
    const url = `${window.location.origin}/apigateway/style/api//v1/delete/style?styleId=` + styleId;
    return this.httpClientService.remove(url, styleId);
  }

  /**
   * get Range from server
   * @param factoryId used to get range of selected factory
   */
  getRange(factoryId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/get/factory/range?factoryId=` + factoryId;
    return this.httpClientService.get(url);
  }

  /**
   * create Range
   * @param data includes factory id and rangeName to create
   */
  createRange(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/range`;
    return this.httpClientService.post(url, data);
  }

  /**
   * update range
   * @param data includes factory id and rangeName to update
   */
  updateRange(data, id) {
    const url = `${window.location.origin}/apigateway/style/api/v1/range/${id}`;
    return this.httpClientService.put(url, data);
  }

  /**
   * to search range
   * @param rangeName to be search
   * @param factoryId for specific factory
   */
  searchRange(rangeName, factoryId) {
    const listOfCompany = this.httpService
      .get('/apigateway/style/api/v1/auto/complete/range?rangeName=' + rangeName + '&factoryId=' + factoryId)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length !== 0 ? (data as any[]) : [{ 'Company Name': 'No Company Found' } as any];
        })
      );
    return listOfCompany;
  }

  /**
   * get Category from server
   * @param factoryId used to get range of selected factory
   */
  getCategory(factoryId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/get/factory/category?factoryId=` + factoryId;
    return this.httpClientService.get(url);
  }

  /**
   * create category
   * @param data includes factory id and categoryName to create
   */
  createCategory(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/category`;
    return this.httpClientService.post(url, data);
  }

  /**
   * updateCategory
   * @param data includes factory id and categoryName to create
   */
  updateCategory(data, categoryId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/category/${categoryId}`;
    return this.httpClientService.put(url, data);
  }

  /**
   * to search category
   * @param categoryName to be search
   * @param factoryId for specific factory
   */
  searchCategory(categoryName, factoryId) {
    const listOfCategory = this.httpService
      .get('/apigateway/style/api/v1/auto/complete/category?categoryName=' + categoryName + '&factoryId=' + factoryId)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length !== 0 ? (data as any[]) : [{ 'Category Name': 'No Category Found' } as any];
        })
      );
    return listOfCategory;
  }

  /**
   * create size Grid
   * @param data includes factory id and sizeGridName to create
   */
  createSizeGrid(data) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/sizegrid`;
    return this.httpClientService.post(url, data);
  }

  /**
   * get Size Grid from server
   * @param factoryId used to get range of selected factory
   */
  getSizeGrid(factoryId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/get/factory/gridsize?factoryId=` + factoryId;
    return this.httpClientService.get(url);
  }

  /**
   * create Size for avaialable Grid
   * @param gridId respective id to create size
   * @param sizeValue name of size
   */
  createSize(createsizeData) {
    const url = `${window.location.origin}/apigateway/style/api/v1/create/size`;
    return this.httpClientService.post(url, createsizeData);
  }

  /**
   * get available Size from server
   * @param sizeGridId used to get range of selected factory
   */
  getAvailableSize(sizeGridId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/get/gridsize/size?gridSizeId=` + sizeGridId;
    return this.httpClientService.get(url);
  }

  /**
   * to search size grid
   * @param sizeGridName to be search
   * @param factoryId for specific factory
   */
  searchSizeGrid(sizeGridName, factoryId) {
    const listOfCategory = this.httpService
      .get('/apigateway/style/api/v1/auto/complete/gridsize?gridSizeName=' + sizeGridName + '&factoryId=' + factoryId)
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length !== 0 ? (data as any[]) : [{ 'Category Name': 'No Category Found' } as any];
        })
      );
    return listOfCategory;
  }

  /**
   * to search size grid
   * @param sizeCustomerName to be search
   * @param factoryId for specific factory
   */
  searchCustomer(sizeCustomerName, factoryId) {
    const listOfCategory = this.httpService
      .get(
        '/apigateway/style/api/v1/auto/complete/customer?customerName=' + sizeCustomerName + '&factoryId=' + factoryId
      )
      .pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length !== 0 ? (data as any[]) : [{ 'Category Name': 'No Category Found' } as any];
        })
      );
    return listOfCategory;
  }
  deleteSizeGrid(gridId) {
    // apigateway/style/api/v1/sizeGrid/d
    const url = `${window.location.origin}/apigateway/style/api/v1/sizeGrid/${gridId}`;
    return this.httpClientService.remove(url, {});
  }
  updateSizeGrid(data, gridId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/sizeGrid/${gridId}`;
    return this.httpClientService.put(url, data);
  }
  deleteSize(sizeId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/size/${sizeId}`;
    return this.httpClientService.remove(url, {});
  }
  updateSize(data, id) {
    const url = `${window.location.origin}/apigateway/style/api/v1/sizeGrid/${id}`;
    return this.httpClientService.put(url, data);
  }
  deleteRange(rangeId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/range/${rangeId}`;
    return this.httpClientService.remove(url, {});
  }
  deleteCategory(categoryId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/category/${categoryId}`;
    return this.httpClientService.remove(url, {});
  }

  uploadTechPack(documentId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/style/documents`;
    return this.httpClientService.post(url, documentId);
  }

  removeTechPack(styleId, documentId) {
    const url = `${window.location.origin}/apigateway/style/api/v1/style/${styleId}/documents/${documentId}`;
    return this.httpClientService.remove(url, {});
  }

  /**
   * Search style
   */
  searchStyle(searchValue,sortedBy) {
    const url = `${window.location.origin}/apigateway/style/api/v1/search/style/user?searchValue=${searchValue}&sortedBy=${sortedBy}`;
    return this.httpClientService.get(url);
  }
}
