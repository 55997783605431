const bAllowance = [
    { value: '0.00' },
    { value: '0.01' },
    { value: '0.02' },
    { value: '0.03' },
    { value: '0.04' },
    { value: '0.05' },
    { value: '0.06' },
    { value: '0.07' },
    { value: '0.08' },
    { value: '0.09' },
    { value: '0.10' },
];
const stitches = [
    { value: '2.0' },
    { value: '2.5' },
    { value: '3.0' },
    { value: '3.5' },
    { value: '4.0' },
    { value: '4.5' },
    { value: '5.0' },
    { value: '5.5' },
    { value: '6.0' },
    { value: '6.5' },
    { value: '7.0' },
    { value: '7.5' },
    { value: '8.0' },
    { value: '8.5' },
    { value: '9.0' },
    { value: '9.5' },
    { value: '10.0' },
    { value: '0.0' },
    { value: '11.0' },
    { value: '12.0' },
    { value: '13.0' },
    { value: '14.0' },
    { value: '15.0' },
    { value: '16.0' },
    { value: '17.0' },
    { value: '18.0' },
    { value: '19.0' },
    { value: '20.0' },
];

const proSubAnalysisColumns = [
    { field: 'seqNo', header: 'PRO_SMV.S_NO' },
    { field: 'code', header: 'PRO_SMV.CODE' },
    { field: 'frequency', header: 'PRO_SMV.FREQ' },
    { field: 'desc', header: 'PRO_SMV.DESCRIPTION' },
    { field: 'tmu', header: 'PRO_SMV.TMU' },
    { field: 'extTmu', header: 'PRO_SMV.EXT_TMU' },
    { field: 'allowance', header: 'PRO_SMV.ALLOWANCE' },
    { field: 'tmuAllowed', header: 'PRO_SMV.TMU_ALLOWED' },
];

const proAnalysisHistoryColumns = [
    { field: 'seqNo', header: 'PRO_SMV.OP_NO' },
    { field: 'code', header: 'PRO_SMV.DESCRIPTION' },
    { field: 'frequency', header: 'PRO_SMV.SMV' },
    { field: 'desc', header: 'PRO_SMV.APPROVED_BY' },
    { field: 'tmu', header: 'PRO_SMV.TIME_OF_ACTION' },
    { field: 'extTmu', header: 'PRO_SMV.ACTION' },
    { field: 'allowance', header: 'PRO_SMV.GRID_CAT' },
    { field: 'tmuAllowed', header: 'PRO_SMV.SIZE' },
    { field: 'tmuAllowed', header: 'PRO_SMV.STAGE' },
];

export { proSubAnalysisColumns };
export { proAnalysisHistoryColumns };
export { bAllowance };
export { stitches };
