import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.sass']
})
export class RecoverPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
