import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flipInY, bounce, bounceIn, rollIn } from 'ng-animate';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpclientService } from '../shared/httpclient.service';
import { CustomerService } from './customer.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Http } from '@angular/http';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CustomerModel } from './customer.model';
import Swal from 'sweetalert2';
import { SharedService } from '../shared/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-and-supplier',
  templateUrl: './customer-and-supplier.component.html',
  animations: [
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))])
  ],
  styleUrls: ['./customer-and-supplier.component.sass']
})
export class CustomerAndSupplierComponent implements OnInit {
  displayMode = 1;
  flip: any;
  bounce: any;
  bounceIn: any;
  rollIn: any;
  userListDTO: any;
  brandListDTO: any;
  factoryIdDTO: any;
  createNewBrand: any = {};
  createCustomer: any = {};
  userUpdate: any = {};
  searchCompany: FormControl = new FormControl();
  companySearchList: Array<any> = [];
  text: string;
  results: Array<any> = [];
  resultsFactory: Array<any> = [];
  resultsRole: Array<any> = [];
  companyId: any;
  id: any;
  coList: any;
  roleList: any;
  factoryList: any;
  optionSelected: string;
  searchCustomer: string;
  custAuthData: any = {};
  brandAuthData: any = {};
  spinner = true;
  isEditBrand = false;
  editbrandData: any = {};
  brandName: any;


  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private customerService: CustomerService,
    private http: Http,
    public sharedService: SharedService,
    private toastr: ToastrService,
    private service: CustomerService,
     private translate: TranslateService
  ) {
    this.optionSelected = 'sort';
    this.createCustomer.customerName = null;
    this.createCustomer.factoryId = null;
    this.createCustomer.mobileNumber = null;
    this.createCustomer.email = null;
    this.createCustomer.customerType = null;
    this.createCustomer.address = null;
    // this.createNewBrand.name = 'null';
    this.searchCustomer = '';
  }

  ngOnInit() {
    this.searchCompany.valueChanges.subscribe(companyName => {
      if (companyName !== '') {
        this.service.search(companyName).subscribe((data: any) => {
          const companyList = [];
          for (const comp of data.data) {
            comp.name = comp.name;
            companyList.push(comp.id);
          }
          this.companySearchList = companyList;
        });
      }
    });
    this.getUserList();
    this.getFactoryId();
    // setTimeout(() => {
    this.getAuthData();
    // }, 1000);
  }

  getAuthData() {
    const auth = this.sharedService.authData$.subscribe((data: any) => {
      const authData = data.find((el) => {
        return el.name === 'Style & Order';
      });
      const custAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'Customer and Supplier';
      });
      const brandAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'Brand';
      });
      this.custAuthData = custAuthData;
      this.brandAuthData = brandAuthData;
    });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  search(event) {
    this.service.search(event.query).subscribe((response: any) => {
      const companyList = [];
      for (const comp of response.data) {
        comp.name = comp.name;
        companyList.push(comp.name);
      }
      this.results = companyList;
      this.coList = response.data;
    });
  }

  captureId(event: any) {
    for (var i = 0; i < this.coList.length; i++) {
      if (this.coList[i].name === event) {
        this.companyId = this.coList[i].id;
      }
    }
  }

  searching(event) {
    this.service.searching(event.query, this.companyId).subscribe((response: any) => {
      const roleList = [];
      for (const role of response.data) {
        role.name = role.name;
        roleList.push(role.name);
      }
      this.resultsFactory = roleList;
      this.roleList = response.data;
    });
  }

  captureRoleId(event: any) {
    for (var i = 0; i < this.roleList.length; i++) {
      if (this.roleList[i].name === event) {
        this.resultsFactory = this.roleList[i].id;
      }
    }
  }

  searchFactory(event) {
    this.service.searchFactory(event.query).subscribe((response: any) => {
      const factoryList = [];
      for (const factory of response.data) {
        factory.name = factory.name;
        factoryList.push(factory.name);
      }
      this.resultsRole = factoryList;
      this.factoryList = response.data;
    });
  }

  captureFactoryId(event: any) {
    for (var i = 0; i < this.factoryList.length; i++) {
      if (this.factoryList[i].name === event) {
        this.resultsRole = this.factoryList[i].id;
      }
    }
    this.createCustomer.factoryNames.push(event);
  }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }

  openNewCustomerModal() {
    this.createCustomer = {factoryId:this.createCustomer.factoryId};
    this.ngxSmartModalService.getModal('myModal').open();
  }

  getBrands() {
    this.brandListDTO = [];
    this.customerService.getBrands(this.createNewBrand.customerId).subscribe((response: any) => {
      if (response.data) {
        this.brandListDTO = response.data;
      } else {
      }
    });
  }

  openNewBrandModal(data, form: NgForm) {
    // if (this.brandAuthData.permission.canView) {
    this.ngxSmartModalService.getModal('myModal3').open(data);
    this.createNewBrand.customerId = data.id;
    this.getBrands();
    // } else {
    // this.toastr.error("You don't have access to view Brands", 'Failed');
    // }
  }

  openBrandModal(data) {
    if (this.brandAuthData.permission.canView) {
      this.ngxSmartModalService.getModal('myModal4').open(data);
      this.createNewBrand.customerId = data.id;
      this.getBrands();
    } else {
      this.toastr.error("You don't have access to view Brands", 'Failed');
    }
  }

  openUserUpdateModal(data) {
    this.ngxSmartModalService.getModal('myModal1').open(data);
    this.userUpdate.customerType = data.customerType;
    this.userUpdate.factoryName = data.factory.name;
    this.userUpdate.factoryId = data.factory.id;
    this.userUpdate.name = data.name;
    this.userUpdate.mobileNumber = data.mobileNumber;
    this.userUpdate.email = data.email;
    this.userUpdate.address = data.address;
    this.userUpdate.customerId = data.id;
  }

  checkValue(userId: number, status: boolean) {
    this.customerService.statusUser(userId, status).subscribe((response: any) => {
      this.toastr.success('User Status Updated', 'Success');
    });
  }

  onCustomerUpdate(form: NgForm) {
    this.customerService.updateCustomer(this.userUpdate, this.userUpdate.customerId).subscribe(
      result => {
        form.reset();
        // window.location.reload();
        this.toastr.success(result.message, 'Success');
        this.getUserList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  addBrand(form: NgForm) {
    this.createNewBrand.brandName = this.brandName;
    if (this.isEditBrand) {
      const brandData = {
        brandName: this.brandName
      }
      this.customerService.updateBrand(brandData, this.editbrandData.brandId).subscribe(
        result => {
          form.reset();
          // window.location.reload();
          this.isEditBrand = false;
          this.toastr.success(result.message, 'Success');
          this.getBrands();
          this.getUserList();
        },
        error => {
          this.toastr.error(error.error.message, 'Error');
        }
      );
    } else {
      this.customerService.createBrand(this.createNewBrand, this.createNewBrand.customerId).subscribe(
        result => {
          form.reset();
          // window.location.reload();
          this.toastr.success(result.message, 'Success');
          this.getBrands();
          this.getUserList();
        },
        error => {
          this.toastr.error(error.error.message, 'Error');
        }
      );
    }

  }

  getAllBrands() {
    this.customerService.getBrands(this.createNewBrand.customerId).subscribe((response: any) => {
      if (response.data) {
        this.brandListDTO = response.data;
      } else {
      }
    });
  }

  getUserList() {
    this.spinner = true;
    this.customerService.getCustomer().subscribe((response: any) => {
      if (response.data) {
        this.userListDTO = response.data;
        this.spinner = false;
      } else {
      }
    });
  }

  getFactoryId() {
    this.customerService.getFactoryId().subscribe((response: any) => {
      if (response.data) {
        this.factoryIdDTO = response.data;
        this.createCustomer.factoryId = this.factoryIdDTO[0].id
      } else {
      }
    });
  }

  oncreateCustomer(form: NgForm) {
    this.customerService.createCustomer(this.createCustomer).subscribe(
      result => {
        form.reset();
        this.toastr.success(result.message, 'Success');
        this.getUserList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  deleteConsumer(list) {
     this.translate.get(['DELETE_CONFIRM_TITLE', 'DELETE_CONFIRM_TEXT', 'DELETE_CONFIRM_BUTTON', 'DELETE_CANCEL_BUTTON', 'DELETE_SUCCESS','FILE_DELETED']).subscribe(translations => {
    Swal.fire({
      title: translations['DELETE_CONFIRM_TITLE'],
      text: translations['DELETE_CONFIRM_TEXT'], 
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: translations['DELETE_CONFIRM_BUTTON'], 
      cancelButtonText: translations['DELETE_CANCEL_BUTTON'] 
    }).then(result => {
      if (result.value === true) {
        this.customerService.deleteConsumer(list.id).subscribe((response: any) => {
          Swal.fire(translations['DELETE_SUCCESS'],translations['FILE_DELETED'], 'success');
          const index: number = this.userListDTO.indexOf(list);
          this.userListDTO.splice(index, 1);
          this.toastr.success(response.message, 'Success');
        }, (error) => {
          this.toastr.error(error.error.message, 'Error');
        });
      }
    });
  })
}

  editBrand(brand) {
    this.isEditBrand = true;
    this.brandName = brand.name;
    this.editbrandData.brandId = brand.id;
  }

  resetBrand() {
    this.isEditBrand = false;
    this.brandName = '';
  }

  /**
   * delete  brand
   * @param id brandId
   */
  deleteBrand(brandId) {
    this.translate.get(['DELETE_CONFIRM_TITLE', 'DELETE_CONFIRM_TEXT', 'DELETE_CONFIRM_BUTTON', 'DELETE_CANCEL_BUTTON', 'DELETE_SUCCESS','FILE_DELETED']).subscribe(translations => {
    Swal.fire({
      title: translations['DELETE_CONFIRM_TITLE'],
      text: translations['DELETE_CONFIRM_TEXT'], 
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: translations['DELETE_CONFIRM_BUTTON'], 
      cancelButtonText: translations['DELETE_CANCEL_BUTTON'] 
    }).then(result => {
      if (result.value === true) {
        this.customerService.deleteBrand(brandId).subscribe((response: any) => {
          this.spinner = false;
         Swal.fire(translations['DELETE_SUCCESS'],translations['FILE_DELETED'], 'success');

          this.toastr.success(response.message, 'Success');
          this.getAllBrands();
        }, (error) => {
          this.toastr.error(error.error.message, 'Error');
        });
      }
    });
  })
}
}