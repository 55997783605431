import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flipInY,bounce,bounceIn,rollIn } from 'ng-animate';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  animations: [
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))])
  ],
  styleUrls: ['./license.component.sass']
})
export class LicenseComponent implements OnInit {
  flip: any ;
  bounce: any ;
  bounceIn: any ;
  rollIn: any ;

  constructor() { }

  ngOnInit() {
  }

}
